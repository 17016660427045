@use "../../../../scss/break-points";
@use "../../../../scss/colors";

.textContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.chatSetting {
    padding-left: 25px !important;
    padding-right: 25px !important;

    @media (max-width: break-points.$sm) {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}

.title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    opacity: 60%;

    @media (max-width: break-points.$sm) {
        font-size: 11px;
        line-height: 16px;
    }
}

.feature {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    opacity: 60%;

    @media (max-width: break-points.$sm) {
        line-height: 16px;
        font-size: 12px;
    }
}

.featureTextRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.switchTextContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;

    @media (max-width: break-points.$sm) {
        gap: 10px;
    }
}

.switchText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.percentage {
    color: colors.$primaryColor;
}

.planbutton {
    padding-left: 40px !important;
    padding-right: 40px !important;

    .text {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: colors.$lightThemeColor;

        @media (max-width: break-points.$sm) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.button {
    max-width: 127px;

    @media (max-width: break-points.$sm) {
        max-width: 90px;
    }

    .text {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: colors.$white;

        @media (max-width: break-points.$sm) {
            font-size: 11px;
            line-height: 22px;
        }
    }
}

.usageTag {
    background-color: colors.$chatSettingUsageTag;
    -moz-background-color: colors.$chatSettingUsageTag;
    border-radius: 300px;
    padding: 1px 7px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    color: colors.$primaryColor;
    
    @media (max-width:break-points.$sm) {
       font-size: 11px;
    }
}

.removeUsageTage {
    display: block;

    @media (max-width:470px) {
        // display: none;
    }
}