@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.DropDownbody {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 180px;
    overflow-y: auto;

    .message {
        width: 100%;
        text-align: center;
        color: colors.$white;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;

        @media (max-width: break-points.$sm) {
            font-size: 14px;
        }
    }
}

.icon {
    cursor: pointer;

    &.light {
        svg {
            & path {
                fill: colors.$textColorLight;
            }
        }
    }

    &.dark {
        svg {
            & path {
                fill: colors.$white;
            }
        }
    }
}


.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;

    @media (max-width: break-points.$sm) {
        margin-bottom: 15px;
    }

    .imagedropdownContainer {
        width: 45px;
        height: 35px;
        background-color: transparent;
        border-radius: 25px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .textContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 20px;

        @media (max-width: break-points.$sm) {
            margin-left: 15px;
        }

        .modalName {
            font-size: 14px;
            font-weight: 700;
            line-height: 20px;

            @media (max-width: break-points.$sm) {
                font-size: 13px;
            }
        }

        .dropdownModalName {
            font-size: 11px;
            font-weight: 700;
            line-height: 20px;

            &.dark {
                color: colors.$lightThemeColor;
            }

        }

        .modelCreditPerQuery {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            opacity: 0.6;

            &.dark {
                color: colors.$lightThemeColor;
            }

            @media (max-width: break-points.$sm) {
                font-size: 13px;
            }
        }
    }
}