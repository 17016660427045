@use "../../../../../scss/fonts";
@use "../../../../../scss/colors";
@use "../../../../../scss/break-points";

.head {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &.light {
    color: colors.$textColorLightMode;
  }

  &.dark {
    color: colors.$darkModeText;
  }

  @media (max-width: break-points.$md) {
    display: none;
  }

  .headLeft {
    margin-left: 37px;

    &.isSidebarOpen {
      margin-left: 20px;
    }

    &.isSidebarClose {
      @media (min-width: 775px) {
        margin-left: 115px;
      }
    }

    .title {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;

      @media (max-width: 1030px) {
        font-size: 18px;
      }
    }

    .text {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
      opacity: 0.7;
      display: inline-block;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: 1030px) {
        font-size: 14px;
        margin-top: 2px;
        max-width: 250px;
      }
    }
  }

  .btnContent {
    display: flex;
    flex-direction: row;
    align-items: center;
      color: colors.$darkModeText;

      svg {
        & path {
          stroke: colors.$darkModeText;
        }
      }

    .plusIcon {
      width: 22px;
      height: 22px;

      @media (max-width: 1030px) {
        width: 18px;
        height: 18px;
      }
    }

    .text {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-left: 10px;

      @media (max-width: 1030px) {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
}
