@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.container {
  min-width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0;

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 10px 10px 0 0;
    padding: 9px 16px;
    box-sizing: border-box;
    min-height: 46px;

    @media (max-width: break-points.$sm) {
      min-height: 40px;
    }

    .language {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;

      @media (max-width: break-points.$sm) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  .codeContainer {
    width: 100%;
  }

  .preTag {
    font-size: 16px;
    line-height: 24px;
    padding: 16px;

    @media (max-width: break-points.$sm) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 10px 10px;
    padding: 12px 0;
    box-sizing: border-box;

    button {
      padding: 4px 19px;
      border-radius: 10px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      @media (max-width: break-points.$sm) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}

.containerDark {
  .header {
    background-color: colors.$codeBlockBackground;

    .language {
      color: colors.$darkModeText;
    }
  }

  .preTag {
    color: colors.$darkModeText;
    background-color: colors.$codeBlock;
  }

  .footer {
    background-color: colors.$codeBlock;

    button {
      background-color: colors.$codeBlockBackground;
      color: colors.$darkModeText;
    }
  }
}

.containerLight {
  .header {
    background-color: colors.$codeBlockLight;

    .language {
      color: colors.$textColorLight;
    }
  }

  .preTag {
    background-color: colors.$codeBlockLightMode;
  }

  .footer {
    background-color: colors.$codeBlockLightMode;

    button {
      background-color: colors.$codeBlockLight;
      color: colors.$textColorLight;
    }
  }
}
