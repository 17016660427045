@use "../../../../../scss/variables";
@use "../../../../../scss/colors";
@use "../../../../../scss/break-points";

.container {
  width: 100%;
  color: colors.$darkModeText;

  @media (max-width:break-points.$sm) {
    height: 100%;
  }

  &.light {
    color: colors.$textColorLightMode;
  }

  &.dark {
    color: colors.$darkModeText;
  }

  &.team {
    color: colors.$darkModeText;
  }

  .icon{
    display: flex;
    gap: 12px;
    padding: 10px 0px;
      &.light {
          svg {
              & path {
                  fill: colors.$textColorLightMode;
              }
          }
      }
  }

  .h2 {
    font-size: 23px;
    font-weight: 700;
  }

  .memu {
    margin-top: 16px;

    @media (max-width:break-points.$md) {
      margin-top: 0px;
      height: 100%;
    }

    .footer {
      width: 100%;
      padding: 18px 0;

      &.light {
        border-top: 1px solid colors.$borderLight;
      }

      &.dark {
        border-top: 1px solid colors.$settingSideBarBorderTop;
      }
    }

    .menuItem {
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 14px 10px;
      margin-left: -10px;
      cursor: pointer;
      gap: 12px;
      z-index: 99999999;

      @media (max-width:break-points.$md) {
        padding: 7px 10px;
      }

      .text {
        display: inline-block;
        font-size: 15px;
        line-height: 22px;

        &.active{
          color: colors.$primaryColor;
        }
      }
    }
  }
}

.hideCredits {
  @media (max-width:break-points.$md) {
    display: none;
  }
}

.optionIcon{
  position: absolute;
  right: 0px;
}