// @use '../../../../../../../scss/colors';
@use '../../../../../../../scss/break-points';

.footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }

  .buttonText{
    color: #9F75FF;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .btnpadding{
    padding-left: 20px;
    padding-right: 20px;
    @media (max-width: break-points.$sm) {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 13px;
    }
  }
  .textheadSize{
    font-size: 22px;
    @media (max-width: break-points.$sm) {
        font-size: 16px;
    }
  }

  .textSize{
    font-size: 18px;
    @media (max-width: break-points.$sm) {
        font-size: 14px;
    }
  }