@use "../../../../../scss/break-points";
@use "../../../../../scss/colors";
@use '../../../../../scss/variables';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.light {
    color: colors.$textColorLight;

    svg {
      & path {
        fill: colors.$textColorLight;
      }
    }
  }

  &.dark {
    color: colors.$white;

    svg {
      & path {
        fill: colors.$white;
      }
    }
  }

  .icon {
    cursor: pointer;
  }

  .brandContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;

    .brandName {
      font-family: Montserrat;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-left: 37px;
    }
  }

  .menuContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    .HistoryIconsmall {
      @media (max-width:break-points.$md) {
        display: none;
      }
    }
  }
  .menuShareContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;

    .HistoryIconsmall {
      @media (max-width:break-points.$md) {
        display: none;
      }
    }
  }

    .pageTitle {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      margin-left: 37px;

      @media (max-width:break-points.$sm) {
        width: 100%;
        // margin-left: 0px;
      }

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .logoutButton {
      padding: 5px 6px;
      border-radius: 10px;
      background-color: transparent;
  
      &.light {
        border: 1px solid rgba(colors.$textColorLightMode, 0.2);
      }
  
      &.dark {
        border: 1px solid rgba(colors.$darkModeText, 0.2);
      }
  
      .btnContent {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
  
        &.light {
          color: colors.$textColorLight;
  
          svg {
            & path {
              stroke: colors.$textColorLight;
            }
          }
        }
  
        &.dark {
          color: colors.$white;
  
          svg {
            & path {
              stroke: colors.$white;
            }
          }
        }
  
        .text {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
  
          @media (max-width: break-points.$sm) {
            font-size: 13px;
            line-height: 18px;
          }
        }
  
        .icon {
          width: 22px;
          height: 22px;
  
          @media (max-width: break-points.$sm) {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
  .shareChatbtn {
    border: none;
    background: transparent;
  }
  
  .shareChatTryBtn {
    border: none;
    background-color: transparent;
    color: colors.$primaryColor !important;
  }