@use "../../../../../../scss/colors";
@use "../../../../../../scss/break-points";

.container {
  width: 100%;
  border-radius: 30px;
  padding: 10px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  cursor: pointer;

  &.light {
    color: rgba(colors.$textColorLight, 0.9);
    border: 1px solid colors.$docBorder;

    svg {
      & path {
        fill: colors.$textColorLight;
      }
    }
  }

  &.dark {
    color: colors.$white;
    border: 1px solid colors.$borderDark;
    background: colors.$darkModeSetting;

    svg {
      & path {
        fill: colors.$white;
      }
    }
  }

  @media (max-width: break-points.$sm) {
    padding: 5px 10px;
    gap: 10px;
    border-radius: 10px;
  }

  .text {
    white-space: pre-line;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    width: 100%;
    user-select: none;

    @media (max-width: break-points.$sm) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .icon {
    min-width: 22px;
    max-width: 22px;
    min-height: 22px;
    max-height: 22px;

    @media (max-width: break-points.$sm) {
      min-width: 18px;
      max-width: 18px;
      min-height: 18px;
      max-height: 18px;
    }
  }
}
