@use '../../scss/colors';

.container {
    display: flex;
    align-items: center;
}

.cursor {
    // display: inline-block;
    // width: 6px;
    // height: 20px;
    // border-radius: 1px;
    padding: 0 3px;
    margin-left: 4px;
    background-color: colors.$darkModeText;
    animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}  