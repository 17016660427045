@use "../../../../scss/colors";

.headline {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }

  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.modalFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.btnText {
  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}
