@use "../../../scss/variables";
@use "../../../scss/break-points";
@use "../../../scss/colors";

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;

  &.lightSettings {
    background-color: colors.$mainViewColor;
  }

  &.lightChat {
    background-color: colors.$lightThemeColor;
  }

  &.dark {
    background-color: colors.$mainViewDarkMode;
  }

  &.chatContainer {
    padding: 0 variables.$pagePadding 0 0;

    &.isSidebarOpen {
      
      @media (min-width: 775px) {
        padding: 0 variables.$pagePadding 0 0;
      }
     padding: 0 variables.$pagePadding 0 variables.$pagePadding;
      @media  (max-width:break-points.$sm) {
        padding: 0;
      }
    }
  }

  &.settingContainer {
    padding: 0 variables.$pagePadding variables.$pagePadding 0;

    &.isSidebarOpen {
      padding: 0 variables.$pagePadding variables.$pagePadding
        variables.$pagePadding;
        @media (min-width: 775px) {
          padding: 0 variables.$pagePadding variables.$pagePadding 0;
        }
        @media  (max-width:break-points.$sm) {
          padding: 0;
        }
    }
  }

  &.isShareChat{
    background-color: colors.$shareChatLight;
  }

  &.dragbackdrop{
    position: fixed;
    // position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: colors.$modalBackDrop;
    z-index: 99999;
  }
}

.content {
  width: 100%;
  margin-left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  padding: 16px 0;
  transition: width variables.$sb-transition ease,
    margin-left variables.$sb-transition ease;
  border-radius: 20px;

  &.light {
    background-color: colors.$white;
  }

  &.dark {
    background-color: colors.$mainViewDarkMode;
  }

  &.isSidebarOpen {
    width: calc(100% - variables.$sidebaropen);
    margin-left: variables.$sidebaropen;
    transition: width variables.$sb-transition ease,
      margin-left variables.$sb-transition ease;

    @media (max-width: break-points.$md) {
      width: 100%;
      margin-left: 0;
      transition: width none, margin-left none;

    }
  }

  @media (min-width: 775px) {
    &.isSideBarClose{
      margin-left: variables.$miniSidebaropen;
    }
  }

  &.isShareChat{
    margin-left: 40px;
    width:100%;
    transition: width variables.$sb-transition ease;
  }

  @media (max-width: break-points.$md) {
    width: 100%;
    margin-left: 0;
    padding: 15px;
  }

  &.shareChat{
    background-color: colors.$shareChatLight;
  }

  &.dragBackdropOpen {
    position: relative;
    z-index: 10002; /* Adjust the z-index to be above the backdrop */
  }
 
}

.chatContent {
  height: calc(
    100% - (variables.$navbar + variables.$chatFooter + variables.$pagePadding)
  );
  margin-top: variables.$navbar;
  flex-direction: column;
  padding: 0;
  border-radius: 20px 20px 0 0;

  @media (max-width: break-points.$md) {
    height: calc(
      100% -
        (variables.$md-navbar + variables.$chatFooter + variables.$pagePadding)
    );
    margin-top: variables.$md-navbar;
  }

  @media (max-width: break-points.$sm) {
    padding: 0 0 15px 0;
    height: calc(
      100% -
        (
          variables.$md-navbar + variables.$chatFooterSM +
            variables.$pagePadding
        )
    );
  }
}

.IGChatContent {
  height: calc(
    100% -
      (variables.$navbar + variables.$IGChatFooter + variables.$pagePadding)
  );

  @media (max-width: break-points.$md) {
    height: calc(
      100% -
        (
          variables.$md-navbar + variables.$IGChatFooter +
            variables.$pagePadding
        )
    );
    margin-top: variables.$md-navbar;
  }

  @media (max-width: break-points.$sm) {
    height: calc(
      100% -
        (
          variables.$md-navbar + variables.$IGChatFooterSM +
            variables.$pagePadding
        )
    );
  }
}

.IChatContent{
  height: calc(
    100% -
      (variables.$navbar + variables.$IChatFooter + variables.$pagePadding)
  );
}
.IUplodingFile{
  height: calc( 100% - (279px));
}

.settingsContent {
  height: calc(100% - variables.$navbar);
  margin-top: variables.$navbar;


  @media (max-width: break-points.$md) {
    height: calc(100% - (variables.$md-navbar));
    margin-top: variables.$md-navbar;
  }
}

.settingsContentlight{
  background: colors.$darkModeText;
}

.settingsContentdark{
background: colors.$darkModeSetting;
}

.newChatContent{
  height: 100%;
}
