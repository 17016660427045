@use '../../../../../../scss/break-points';
@use '../../../../../../scss/variables';
@use '../../../../../../scss/colors';

.IGOptionContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    top: 10px;
    right: 0;
    padding: 0 163px 0px 82px;
    transition: padding variables.$sb-transition ease;
  
    &.light {
      color: colors.$textColorLightMode;
      opacity: 0.9;
    }
  
    &.dark {
      color: colors.$white;
    }
  
    &.isSidebarOpen {
      padding: 0 163px 0 82px;
      transition: padding variables.$sb-transition ease;

      @media (max-width: break-points.$lg) {
        padding: 0 100px 0 82px;
      }
    }
  
    @media (max-width: break-points.$lg) {
      top: 20px;
      padding: 0 93px 0 20px;
    }
  
    @media (max-width: break-points.$sm) {
      top: 12px;
      padding: 0 50px 0 0;
    }
    @media (max-width: 375px) {
      top: 6px;
      padding: 0 50px 0 0;
    }
  
    .IGOptions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
  
      @media (max-width: break-points.$sm) {
        gap: 10px;
      }
    }
  
    .imageCredits {
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
  
      @media (max-width: break-points.$sm) {
        font-size: 12px;
      }
    }
  }