@use "../../scss/colors";
@use "../../scss/variables";
@use "../../scss/break-points";

.menu {
  display: block;
  margin-top: 20px;
}

.logoContainer {
  display: flex;
  align-items: center;
  min-width: 110px;
}

.list {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 4;
  @media screen and (min-width: 1120px) {
    flex-direction: row;
    padding: 0 20px;
  }
  @media screen and (max-width: 1120px) {
    flex-direction: row;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: space-between;
  }
}
.link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 4px;
  border-radius: 10px;
  border: 1px solid transparent;
  text-decoration: none;
  display: flex;

  @media (max-width: 346px) {
    font-size: 14px;
  }

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }

  @media screen and (min-width: 900px) {
    margin-top: 0px;
    padding: 10px 10px;
    justify-content: center;
  }
}
.smallscreen {
  @media (max-width: 1120px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (max-width: 430px) {
    display: flex;
    flex-direction: column;
  }
}

.social__container {
  display: flex;
  flex-wrap: wrap;
  max-width: 150px;
  // grid-template-columns: repeat(4, 1fr);
  // grid-auto-rows: auto;
  // grid-auto-flow: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-top: 20px;

  @media (max-width: 430px) {
    justify-content: flex-start;
      max-width: 100%;
  }

  .socialBtn {
    background-color: transparent;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    text-align: center;
      width: 24px;
      height: 24px;

    .icon {
      // opacity: 0.7;

      &.light {
        color: colors.$primaryColor20;
        
        &:hover {
          color: colors.$primaryColor;
        }
      }

      &.dark {
        color: colors.$primaryColor20;

        &:hover {
          color: colors.$primaryColor;
        }
      }
    }

    @media screen and (max-width: 900px) {
      // margin-top: 20px;
    }
  }
}

.select {
  height: 44px;
  // width: 160px;
  // @media (max-width: break-points.$sm) {
  //   height: 34px;
  //   width: 100px;
  // }
}
