@use "../../../scss/colors";

.title {
  margin: 30px auto 10px auto;
  text-align: center;
  font-size: 24px;
  font-weight: 700;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }

  @media screen and (min-width: 769px) {
    font-size: 40px;
  }
}

.desc {
  text-align: center;
  line-height: 26px;
  font-size: 16px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
    opacity: 0.7;
  }
}

.pricingSection {
  padding: 60px 15px;
  position: relative;
  @media screen and (min-width: 769px) {
    padding: 160px 5%;
  }

  @media screen and (min-width: 1440px) {
    padding: 100px 10%;
  }
  &.light {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.dark {
    background: rgba(42, 40, 49, 0.5);
  }
}

.planTableDark {
  width: 100%;
  white-space: nowrap;
  color: colors.$white;

  thead {
    th {
      width: 16.6%;
    }

    tr {
      th {
        &:not(:first-child) {
          border-top: 1px solid colors.$landingPriceBorderDark;
        }

        &:nth-child(2) {
          border-left: 1px solid colors.$landingPriceBorderDark;
          border-radius: 20px 0 0 0;
        }

        &:last-child {
          border-right: 1px solid colors.$landingPriceBorderDark;
          border-radius: 0 20px 0 0;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          border-left: 1px solid colors.$landingPriceBorderDark;
        }

        &:last-child {
          border-right: 1px solid colors.$landingPriceBorderDark;
        }
      }

      &:first-child {
        td {
          border-top: 1px solid colors.$landingPriceBorderDark;

          &:first-child {
            border-radius: 20px 0 0 0;
          }
        }
      }

      &:nth-child(2n + 1) {
        background: #2a2831;
      }

      &:last-child {
        td {
          border-bottom: 1px solid colors.$landingPriceBorderDark;

          &:first-child {
            border-radius: 0 0 0 20px;
          }

          &:last-child {
            border-radius: 0 0 20px 0;
          }
        }
      }
    }
  }
}

.planTableLight {
  width: 100%;
  white-space: nowrap;
  color: colors.$textColorLight;

  thead {
    th {
      width: 16.6%;
    }

    tr {
      th {
        &:not(:first-child) {
          border-top: 1px solid colors.$borderLight;
        }

        &:nth-child(2) {
          border-left: 1px solid colors.$borderLight;
          border-radius: 20px 0 0 0;
        }

        &:last-child {
          border-right: 1px solid colors.$borderLight;
          border-radius: 0 20px 0 0;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        &:first-child {
          border-left: 1px solid colors.$borderLight;
        }

        &:last-child {
          border-right: 1px solid colors.$borderLight;
        }
      }

      &:first-child {
        td {
          border-top: 1px solid colors.$borderLight;

          &:first-child {
            border-radius: 20px 0 0 0;
          }
        }
      }

      &:nth-child(2n) {
        background-color: #f3f5f8;
      }

      &:last-child {
        td {
          border-bottom: 1px solid colors.$borderLight;

          &:first-child {
            border-radius: 0 0 0 20px;
          }

          &:last-child {
            border-radius: 0 0 20px 0;
          }
        }
      }
    }
  }
}
