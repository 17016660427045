@use "../../scss/colors";

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  border-bottom: 1px solid rgba(colors.$darkModeText, 0.05);
  box-shadow: 0 1px 10px 1px rgba(20, 21, 23, 0.1);
  backdrop-filter: blur(10px);

  &.light {
    background-color: rgba(colors.$darkModeText, 0.8);
  }
  &.dark {
    box-shadow: 0px 45px 13px 0px rgba(107, 107, 107, 0),
      0px 29px 12px 0px rgba(107, 107, 107, 0.01),
      0px 16px 10px 0px rgba(107, 107, 107, 0.05),
      0px 7px 7px 0px rgba(107, 107, 107, 0.09),
      0px 2px 4px 0px rgba(107, 107, 107, 0.1);
    background: #2a2831;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.container {
  width: 100%;
  padding: 15px 0px;

  @media screen and (min-width: 797px) {
    padding: 0;
  }
  @media screen and (max-width: 576px) {
    padding: 10px 10px;
  }
}

.headerBody {
  position: relative;
  display: flex;
  align-items: center;
  padding: 4px 15px 4px 15px;
  justify-content: space-between;

  @media screen and (min-width: 797px) {
    padding: 0 5%;
  }
}

.headerLogo {
  align-items: center;
  margin-right: 40px;
  color: #292f36;
  display: none;

  @media screen and (min-width: 797px) {
    display: flex;
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

.header_xs_logo {
  display: flex;
  align-items: center;

  img {
    margin-left: 8px;
    max-width: 100px;
  }

  @media screen and (min-width: 797px) {
    display: none;
  }
}

.header__list {
  display: none;
  position: relative;
  z-index: 4;


 
  @media screen and (min-width: 797px) {
    display: flex;
  }
}

.header__li {
  @media screen and (min-width: 797px) {
    position: relative;
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

.header__link {
  display: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  // opacity: 0.6;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid transparent;
  text-decoration: none;
  // margin-left: 16px;
  align-items: center;
  cursor: pointer;

  @media screen and (min-width: 797px) {
    display: flex;
  }
  @media screen and (max-width: 915px) {
    font-size: larger;
    margin-left: -20px;
  }
}

.header__link_dark {
  color: colors.$white;

  &:hover {
    width: fit-content;
    opacity: 1;
    background: linear-gradient(90deg, #4693e8 0%, #7850dd 54.67%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.active {
    opacity: 1;
    background: linear-gradient(90deg, #4693e8 0%, #7850dd 54.67%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.header__link_light {
  color: colors.$textColorLight;

  &:hover {
    opacity: 1;
    // background-color: #E3D8FF;
    background: linear-gradient(90deg, #4693e8 0%, #7850dd 54.67%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &.active {
    opacity: 1;
    // background-color: #E3D8FF;
    background: linear-gradient(90deg, #4693e8 0%, #7850dd 54.67%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.header__link_loginin {
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  border-radius: 10px;
  border: 1px solid transparent;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;

  &.light {
    color: colors.$primaryColor;
  }

  &.dark {
    color: colors.$primaryColor;
  }
}

.header__link_signup {
  display: block;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  border-radius: 10px;
  border: 1px solid transparent;
  text-decoration: none;
  margin-left: 10px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }

  @media screen and (min-width: 797px) {
    display: none;
  }
}


.header__burger {
  display: block;
  position: relative;
  width: 24px;
  height: 16px;
  z-index: 5;
  overflow: hidden;
  margin-left: 10px;

  span {
    background-color: colors.$darkModeText;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 7px;
    transition: all 0.5s ease;
  }

  &:before,
  &:after {
    content: "";
    background-color: colors.$darkModeText;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: all 0.5s ease;
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  &.active {
    &:before {
      transform: rotate(45deg);
      top: 5px;
    }

    &:after {
      transform: rotate(-45deg);
      bottom: 5px;
    }
  }

  @media screen and (min-width: 797px) {
    display: none;
  }
}

.loginIcon {
  display: block;

  @media screen and (min-width: 797px) {
    display: none;
  }
}

.xs {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(92.31deg, colors.$colorLineargrandient 0%, colors.$colorLineargrandient2 100%);
  padding: 100px 0 0 0;
  transition: all 0.5s ease;
  overflow: auto;
  z-index: 20;
}

.xs_path {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.8);
  padding: 16px 20px;
  border-radius: 10px;
  border: 1px solid transparent;
  text-align: center;
}
