@use "../../scss/colors";
@use "../../scss/variables";
@use "../../scss/z-indices";
@use "../../scss/break-points";

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: colors.$modalBackDrop;
  // background: #8a8a8a;
  &.errorBackdrop {
    background:  rgb(21 21 21 / 80%);
  }
}

.modalPageContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: z-indices.$modal;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: fadeIn 0.2s ease-out;
  display: flex;
  overflow-y: auto;
}

.modalPanel {
  margin: auto;
}

.card {
  box-sizing: border-box;
  border-radius: 20px;
  backdrop-filter: blur(25px);
  max-width: calc(100vw - variables.$spacing-lg * 2);

  &.light {
    background-color: colors.$dark-blue-150;
    -moz-background-color: colors.$dark-blue-150;
    color: colors.$textColorLightMode;
    border: 1px solid colors.$modalBorder;
  }

  &.dark {
    color: colors.$darkModeText;
    border: 1px solid colors.$modalBorder;
    background: colors.$modalDarkModeBackground;
  }

  &.isPadding {
    padding: 16px 20px;
  }

  &.isDrag{
    backdrop-filter: blur(0px);
  }

  &.sm {
    width: variables.$width-modal-sm;
  }

  &.md {
    width: variables.$width-modal-md;
  }

  &.lg {
    width: variables.$width-modal-lg;
  }

  &.xl {
    width: variables.$width-modal-xl;
  }

  &.chatSettingSize {
    width: 720px
  }
}

.cardTitle {
  color: colors.$black300;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  padding: 26px 0 35px;
}