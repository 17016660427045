@use "../../../../../../../scss/colors";;

.usageTag {
  background-color: colors.$darkModeText;
  -moz-background-color: colors.$darkModeText;
    border-radius: 300px;
    padding: 1px 7px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 22px;
    color: colors.$textColorLightMode;
  }

  .textcolor{
    color: colors.$darkModeText;
  }