@use "../../../../scss/colors";

.text {
  .light {
    color: colors.$textColorLight;
  }

  .dark {
    color: colors.$white;
  }
}
.setFrequency {
  color: rgba(42, 40, 49, 0.8);
}
.setFrequencydark{
  color: colors.$darkModeText;
}

.separator {
  .light {
    border-bottom: 1px solid colors.$borderLight;
  }

  .dark {
    border-bottom: 1px solid colors.$borderDark;
  }
}
.Pricecardwhite{
  border-radius: 20px;
  padding: 20px;
  background-color:  #f5f5f5;
  color: colors.$textColorLight;
  .text {
    .light {
      color: colors.$textColorLight;
    }
  
    .dark {
      color: colors.$white;
    }
  }
}
.Pricecard{
  border-radius: 20px;
  background-color:  #262627;
  color: colors.$white;
  padding: 20px;
  .text {
    .light {
      color: colors.$textColorLight;
    }
  
    .dark {
      color: colors.$white;
    }
  }
}
.pricelable{
  color: colors.$white;
}
.pricelablewhite{
  color: colors.$textColorLight;
}

.lightText{
  color: colors.$textColorLight;
}

.darkText{
   color: colors.$white;
}