@use "../../../../scss/break-points";
@use "../../../../scss/colors";

.DropDownbody {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 180px;
  overflow-y: auto;
  margin-bottom: 10px;

  .message {
      width: 100%;
      text-align: center;
      color: colors.$white;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;

      @media (max-width: break-points.$sm) {
          font-size: 14px;
      }
  }
}

  // .imagedropdownContainer {
  //     width: 45px;
  //     height: 35px;
  
  // }

  .textContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      // margin-left: 20px;

      @media (max-width: break-points.$sm) {
          margin-left: 15px;
      }

      .modalName {
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;

          @media (max-width: break-points.$sm) {
              font-size: 13px;
          }
      }

      .dropdownModalName {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;

          &.dark {
              color: colors.$lightThemeColor;
          }

          &.light{
            color: #A09FA2;
          }

          @media (max-width: break-points.$sm) {
            font-size: 14px;
          }

      }
  }


.svg {
  border-top: 1px solid  #D9D9D94D;
  margin-bottom: 10px;
}

// UploadURL
.form {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 32px;

  .input {
    width: 100%;
    border-radius: 10px 0px 0px 10px;
    border: 1px solid colors.$primaryColor;
    background-color: rgba(colors.$darkModeText, 0.03);
    backdrop-filter: blur(25px);
    padding: 9px 13px;
    box-sizing: border-box;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    &.light {
      color: colors.$textColorLight;
    }

    &.dark {
      color: colors.$white;
    }

    @media (max-width: break-points.$sm) {
      font-size: 14px;
    }
  }

  button {
    padding: 10px 0;
    min-width: 122px;
    box-sizing: border-box;
    border-radius: 0px 10px 10px 0px;
    background: colors.$primaryColor;
    color: colors.$white;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    @media (max-width: break-points.$sm) {
      min-width: 75px;
      font-size: 14px;
    }

    &.isLoading {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 14.5px 0;
    }
  }
}

.disabled{
  opacity: 0.5; /* Example styling for disabled state */
  pointer-events: none; 
}