@use "../../../../../../../scss/colors";

.itemContainer {
  width: 100%;
  display: grid;
  grid-template-columns: min-content minmax(0, auto);
  align-items: center;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 1px;
  gap: 13px;
  position: relative;
  background-color: transparent;
  padding: 6px 0;
}

.itemContainerDark {
  svg {
    & path {
      fill: colors.$white;
    }
  }

  &:hover {
    opacity: 1;
    background-color: colors.$chatItembackground;
  }

  &.active {
    opacity: 1;
    background-color: colors.$chatItembackground;
  }
}

.itemContainerLight {
  svg {
    & path {
      fill: colors.$lightThemeColor;;
    }
  }

  &:hover {
    opacity: 1;
    background-color: colors.$primaryColor;
  }

  &.active {
    opacity: 1;
    background-color: colors.$primaryColor;
  }
}

.itemText {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  max-width: 100%;

  &.isActive {
    max-width: 140px;
    // max-width: 121px;
  }

  .highlightText {
    color: colors.$darkModeText;
    background-color: colors.$primaryColor;
  }
}

.itemLastMessage {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  
  &.isActive {
    max-width: 140px;
  }

  .highlightText {
    color: colors.$darkModeText;
    background-color: colors.$primaryColor;
  }
}

.itemMsgActionContainer {
  width: 100%;
}

.itemMsgActionContainerDark {
  color: colors.$darkModeText;

  &.active {
    svg {
      & path {
        fill: colors.$darkModeText;
      }
    }
  }
}

.itemMsgActionContainerLight {
  color: colors.$lightThemeColor;

  &.active {
    svg {
      & path {
        fill: colors.$darkModeText;
      }
    }
  }
}

.itemMessageContainer {
  max-width: 100%;
}

.editItemTextfield {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 0px;
  border-bottom: 2px solid colors.$primaryColor;
  background-color: transparent;
  max-width: 121px;
  color: colors.$darkModeText;
  // &.light {
  //   color: colors.$darkModeText;
  // }

  // &.dark {
  //   color: colors.$white;
  // }
}

.actionsContainer {
  width: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.actContainer {
  width: 25px;
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.actionIcon {
  cursor: pointer;
}
.highlightText {
  color: colors.$darkModeText;
  background-color: colors.$primaryColor;
}

.check {
  display: flex;
  align-items: center; 
}

.check input[type="checkbox"] {
  margin-right: 8px; 
  width: 12px;
  height: 12px;
  border-radius: 2px;
  margin-bottom: 3.3px;
  -webkit-appearance: none; 
  background-color: transparent;
  border: 1.3px solid #fff; 
  cursor: pointer; 
  position: relative; 
}
.check input[type="checkbox"]:checked::after {
  content: '✓'; 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff; 
  font-size: 10px; 
  font-weight: bold; 
}