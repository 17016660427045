@use "../../../scss/colors";

.container {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 85%;
  z-index: 100;
  padding: 20px;
  border-radius: 0 20px 20px 0;

  &.light {
    background-color: colors.$white;
  }

  &.dark {
    background-color: colors.$leftpanelDark;
  }
}

.nav {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}

.navItem {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  // opacity: 0.8;
  border-radius: 10px;
  border: 1px solid transparent;
  text-decoration: none;
  padding: 10px;
  width: 100%;
}


.navSubItemHeader{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.arrowIcon {
    display: flex;
  align-items: center;
   transition: transform 0.3s ease;

    &.show {
    transform: rotate(180deg);
  }
}

.navSubItem{
  font-size: 15px;
  display: inline-block;
  padding: 5px 0 5px 20px;
}

.navItemDark {
  color: colors.$white;

  &:hover {
    opacity: 1;
    background-color: #3E345A;
  }

  &.active {
    opacity: 1;
    background-color: #3E345A;
  }
}

.navItemLight {
  color: colors.$textColorLight;

  &:hover {
    opacity: 1;
    background-color: #E3D8FF;
  }

  &.active {
    opacity: 1;
    background-color: #E3D8FF;
  }
}

.signButton {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
}

.mask {
  height: 100%;
  background-color: rgba(colors.$black, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
}
