@use "../../../scss/colors";
@use "../../../scss/break-points";

.section {
  min-height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 15px;


  @media screen and (min-width: 1024px) {
    justify-content: space-between;
    // min-height: calc(100vh - 30%);
    padding: 158px 50px 158px 0px;
    padding: 0 5%;
  }

  @media screen and (min-width: 1440px) {
    padding: 158px 10%;
  }

  &.light {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.dark {
    background: rgba(42, 40, 49, 0.5);
  }
}

.left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  @media screen and (min-width: 1024px) {
    margin-right: 40px;
    max-width: 45%;
  }
}

.h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 20px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }

  @media screen and (min-width: 1024px) {
    font-size: 54px;
    line-height: 58px;
  }
}

.subtitle {
  font-size: 20px;
  line-height: 28px;

  @media (max-width: 346px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 40px;
    line-height: 48px;
  }
}

.text {
  margin-bottom: 40px;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  padding: 0 20px;

  &.light {
    color: colors.$textColorLight;
    opacity: 0.7;
  }

  &.dark {
    color: colors.$white;
    opacity: 0.7;
  }

  @media screen and (min-width: 1024px) {
    font-size: 20px;
    padding: 0;
  }
}

.xs_image {
  width: 90% !important;
  margin-bottom: 34px;
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.lg_image {
  display: none;
  margin-left: 34px;

  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.rightSection {
  // position: relative;
}

.imgBox {
  width: 780px;
  height: 455px;
  display: none;
  align-items: center;
  justify-content: center;
  // position: relative;

  @media screen and (min-width: 1024px) {
    display: flex;
  }
}

.bigImage {
  position: absolute;
  right: 0;
  z-index: 1;
}

.bg {
  position: relative;
  left: -50px;
  top: -50px;
  opacity: 0.4;
  width: 821px;
  height: 821px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #5200ff 0%,
    rgba(20, 21, 23, 0) 100%
  );
}

.secondary {
  width: 360px;
  height: 360px;
  position: absolute;
  left: 0px;
  bottom: -36px;
  opacity: 0.4;
  z-index: -1;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #0001bb 0%,
    rgba(20, 21, 23, 0) 100%
  );
}

.flower {
  position: absolute;
  right: -50px;
  bottom: -140px;
  z-index: 2;
}

.flowerImage {
  animation: spin 5s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 1024px) {
  .left {
    text-align: left;
    align-items: flex-start;
    justify-content: center;
  }

  .rightSection {
    display: block;
  }
}

.gradient {
  // background: linear-gradient(90deg, #6f53ff 0%, #c757ff 54.67%);
  background: linear-gradient(90deg, #4693e8 0%, #7850dd 54.67%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-right: 8px;
}
.disablebr {
  @media (max-width: break-points.$sm) {
    display: none;
  }
}
.allowbr {
  display: none;
  @media (max-width: break-points.$sm) {
    display: block;
  }
}
