@use "../../../../../scss/break-points";
@use "../../../../../scss/colors";

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tab {
  width: 50%;
  padding: 19px 0;
  font-family: Raleway;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  &.light {
    color: colors.$textColorLight;

    svg {
      & path {
        fill: colors.$textColorLight;
      }
    }
  }

  &.dark {
    color: colors.$white;

    svg {
      & path {
        fill: colors.$white;
      }
    }
  }

  &.isSelected {
    color: colors.$primaryColor;
    border-bottom: 2px solid colors.$primaryColor;

    svg {
      & path {
        fill: colors.$primaryColor;
      }
    }
  }

  @media (max-width: break-points.$md) {
    padding: 15px 0;
  }

  @media (max-width: break-points.$sm) {
    padding: 12px 0;
    font-size: 14px;
  }
}

.body {
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 330px;
  overflow-y: auto;

  // @media (max-width: break-points.$md) {
  //   // padding: 20px;
  // }

  // @media (max-width: break-points.$sm) {
  //   // padding: 15px;
  // }

  .message {
    width: 100%;
    text-align: center;
    color: colors.$white;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    padding-bottom: 10px;

    &.light{
      color: colors.$textColorLight;
    }

    @media (max-width: break-points.$sm) {
      font-size: 14px;
    }
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 0 0 27px 0;

  @media (max-width: break-points.$md) {
    padding: 0 0 20px 0;
  }

  @media (max-width: break-points.$sm) {
    padding: 0 0 15px 0;
    gap: 10px;
  }

  .btnText {
    &.light {
      color: colors.$textColorLight;
    }

    &.dark {
      color: colors.$white;
    }
  }
}
