@use "../../../../../../scss/colors";

.tableContainer {
  border-radius: 24px;
  padding: 18px;
  box-sizing: border-box;

  &.light {
    background-color: colors.$lightThemeColor;
  }

  &.dark {
    background-color: colors.$darkModeSetting;
  }
}

.planTable {
  width: 100%;
  white-space: nowrap;

  thead {
    th {
      width: 16.6%;
    }
    tr {
      th {
        &:last-child {
          border-radius: 0 24px 0 0;
        }
      }
    }
  }

  tbody {
    border-radius: 24px;

    tr {
      &:first-child {
        td {
          &:first-child {
            border-radius: 24px 0 0 0;
          }

          &:last-child {
            border-radius: 0 24px 0 0;
          }
        }
      }

      &:last-child {
        td {
          &:first-child {
            border-radius: 0 0 0 24px;
          }

          &:last-child {
            border-radius: 0 0 24px 0;
          }
        }
      }
    }
  }
}

.planTableDark {
  color: colors.$darkModeText;
}

.planTableLight {
  color: colors.$textColorLightMode;

  tbody {
    background-color: colors.$darkModeText;
  }
}

.extraQueryText {
  &.light {
    color: colors.$textColorLightMode;
  }

  &.dark {
    color: colors.$darkModeText;
    // opacity: 0.7;
  }
}

.selectedButton {
  &.light {
    color: colors.$textColorLight;
    background-color: colors.$darkModeText;
    border: 1px solid transparent;
  }

  &.dark {
    color: colors.$white;
    background-color: rgba(255, 255, 255, 0.03);
    border: 1px solid transparent;
  }

  &:hover {
    &.light {
      color: colors.$textColorLight;
    }

    &.dark {
      color: colors.$white;
    }
  }
}

.buttonText {
  &.light {
    color: colors.$textColorLightMode;
  }

  &.dark {
    color: colors.$darkModeText;
  }
}
