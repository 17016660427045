@use "../../../../../../scss/colors";

.tableContainer {
  table {
    background-color: transparent;
    border-collapse: collapse;
    margin-top: 8px;
    thead {
      th {
        padding: 8px;
        box-sizing: border-box;
        overflow-wrap: break-word;
        text-align: left;
      }
    }
    tbody {
      td {
        padding: 8px;
        box-sizing: border-box;
        overflow-wrap: break-word;
        text-align: left;
      }
    }
  }

  .copyTableBtn {
    padding: 2px 16px;
    border-radius: 8px;
  }

  .rows {
    display: flex;
    align-items: center;
    margin: 16px 0;

    .googleSheets {
      padding: 0 16px 0 8px;
    }

    .cursor {
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .icon {
      display: flex;
      align-items: center;
      margin-left: 14px;
    }
  }
}

.tableContainerLight {
  table {
    border: 1px solid colors.$borderLight;
    thead {
      th {
        border: 1px solid colors.$borderLight;
        background-color: colors.$codeBlockLight;
      }
    }
    tbody {
      td {
        border: 1px solid colors.$borderLight;
      }
    }
  }

  .copyTableBtn {
    background-color: colors.$codeBlockLight;
  }

  .rows {
    .cursor {
      &:focus {
        color: colors.$white;
      }
    }
  }
}

.tableContainerDark {
  table {
    border: 1px solid colors.$borderDark;
    thead {
      th {
        border: 1px solid colors.$borderDark;
        background-color: rgb(colors.$darkModeText, 0.16);
      }
    }
    tbody {
      td {
        border: 1px solid colors.$borderDark;
        color: rgb(colors.$darkModeText, 0.9);
      }
    }
  }

  .copyTableBtn {
    background-color: rgb(colors.$darkModeText, 0.2);
  }

  .rows {
    .cursor {
      &:focus {
        color: colors.$white;
      }
    }
  }
}
