@use "../../scss/colors";

.title {
  padding: 48px 0;
  text-align: center;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}

.contentDark {
  line-height: 24px;
  white-space: pre-wrap;
  font-size: 16px;
  padding: 0 20px 100px 20px;
  overflow-x: auto;

  h4 {
    font-size: 18px;
  }

  table {
    border-collapse: collapse;
    min-width: 500px;
  }

  td,
  th {
    border: 1px solid colors.$borderDark;
    padding: 10px;
  }

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}

.contentLight {
  line-height: 24px;
  white-space: pre-wrap;
  font-size: 16px;
  padding: 0 20px 100px 20px;
  overflow-x: auto;
  color: colors.$textColorLight;

  h4 {
    font-size: 18px;
  }

  table {
    border-collapse: collapse;
    min-width: 500px;
  }

  td,
  th {
    border: 1px solid colors.$borderLight;
    padding: 10px;
  }
}
