@use "../../../../../scss/colors";
@use "../../../../../scss/variables";
@use "../../../../../scss/break-points";

.container {
  position: relative;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .modelButton {
    border: none;
    padding: 6px 7px 6px 16px;
    margin: 0;
    border-radius: 300px;

    &.light {
      color: colors.$textColorLightMode;
      background: colors.$darkModeText;

      /* shadow */
      box-shadow: 0px 206px 58px 0px rgba(120, 120, 120, 0.00), 0px 132px 53px 0px rgba(120, 120, 120, 0.01), 0px 74px 45px 0px rgba(120, 120, 120, 0.05), 0px 33px 33px 0px rgba(120, 120, 120, 0.09), 0px 8px 18px 0px rgba(120, 120, 120, 0.10);

      svg {
        & path {
          fill: colors.$textColorLightMode;
        }
      }
    }

    &.dark {
      background: colors.$darkModeSetting;
      color: colors.$darkModeText;

      svg {
        & path {
          fill: colors.$darkModeText;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .modelIconContainer {
        width: 24px;
        height: 24px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .model {
        font-size: 15px;
        font-weight: 700;
        line-height: 20px;
        margin-left: 13px;
      }
    }
  }
}

.creditsContainer {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: variables.$pagePadding;
}

.settingIcon{
  display: flex;
  align-items: center;
  cursor: pointer;
   margin-right: 25px;

}

.LogoContainer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: variables.$pagePadding;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shareChatbtn {
  border: 1.5px solid colors.$primaryColor !important;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: variables.$pagePadding;
}

.shareChatTryBtn {
  border: 1 px solid colors.$primaryColor !important;
  color: colors.$primaryColor !important;
}

.multipleaiModel {
  margin-left: 0.2em;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  &.light {
    color: colors.$textColorLightMode;
  }

  &.dark {
    color: colors.$darkModeText;
  }
}