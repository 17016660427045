@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.nameContainer {
  max-width: 36px;
  max-height: 36px;
  min-width: 36px;
  min-height: 36px;
  border-radius: 100px;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: colors.$darkModeText;
  background: linear-gradient(92.31deg, colors.$colorLineargrandient 0%, colors.$colorLineargrandient2 100%), colors.$userNameBackground;

  @media (max-width: break-points.$sm) {
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    border-radius: 8px;
    font-size: 10px;
  }
}
