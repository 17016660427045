@use "../../../../../../scss/break-points";

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 15px;

  @media (max-width: break-points.$sm) {
    margin-bottom: 15px;
  }
   &:hover{

    &.light{
    background-color: rgba(224, 214, 254, 0.60);
    }

    &.dark{
      background: rgba(62, 35, 97, 0.60);
    }
   }

  .imageContainer {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: break-points.$sm) {
      width: 35px;
      height: 30px;
      border-radius: 25px;
    }
  }

  .textContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 25px;

    @media (max-width: break-points.$sm) {
      margin-left: 15px;
    }

    .modalName {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;

      @media (max-width: break-points.$sm) {
        font-size: 13px;
      }
    }

    .modelCreditPerQuery {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      opacity: 0.6;

      @media (max-width: break-points.$sm) {
        font-size: 13px;
      }
    }
  }
}
