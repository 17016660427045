@use "../../../scss/colors";

.container {
  width: 100%;
  position: relative;
  border: 1px solid rgba(colors.$darkModeText, 0.1);
  border-radius: 10px;
  flex: 1;

  &.lightMode {
    background-color: colors.$inputLightMode;
  }
  &.promptLightMode{
    background-color: transparent;
    border: 1px solid colors.$promptborder;
  }

  &.darkMode {
    background-color: transparent;
  }

  &.grey {
    background-color: colors.$grey-50;
    border: none;
  }

  &.error {
    border-color: transparent;
    border-color: colors.$red;
  }

  &:not(.disabled, .focused):hover {
    background-color: transparent;

    &.lightMode {
      background-color: colors.$inputLightMode;
    }
    &.promptLightMode{
      background-color: transparent;
    }

    &.darkMode {
      background-color: transparent;
    }

    &.error {
      border-color: colors.$red;
    }
  }

  &.focused {
    border-color: colors.$primaryColor;

    &.lightMode {
      background-color: colors.$inputLightMode;
    }
    &.promptLightMode{
      background-color: transparent;
    }

    &.darkMode {
      background-color: transparent;
    }
  }
}

.input {
  outline: none;
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border: none;
  background: none;
  color: colors.$grey-300;
  caret-color: colors.$grey-300;

  &:not(.disabled).password {
    width: calc(100% - 22px);
  }

  &::placeholder {
    color: colors.$grey-300;
  }

  &.disabled {
    pointer-events: none;
    color: colors.$grey-400;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: colors.$grey-300;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
  }

  &.promptLightMode {
    &::placeholder {
      color: colors.$textColorLightMode !important;
      opacity: 0.6;
    }
  }
}

.lightMode {
  color: colors.$textColorLight;
  caret-color: colors.$textColorLight;
  opacity: 1;
  &:focus {
    color: colors.$textColorLight;
  }
  &::placeholder {
    color: colors.$textColorLight;
    opacity: 0.7;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: colors.$textColorLight;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
  }
}

.darkMode {
  color: colors.$white;
  caret-color: colors.$white;
  opacity: 1;
  &:focus {
    color: colors.$white;
  }
  &::placeholder {
    color: colors.$white;
    opacity: 0.7;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: colors.$white;
    transition: background-color 5000s ease-in-out 0s;
    background-color: transparent;
  }
}

button.visibilityButton {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  height: 100%;
  width: 30px;
  align-items: center;
  justify-content: center;
  border: none;
}
