@use "../../../scss/break-points";
.main{
    background-image: url('./Upgradingbg.png'); 
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row; 
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-left: 31px;
        &.dark{
        background-color: #232323;
        padding-left: 76px;
        @media (max-width: break-points.$sm) {
            padding-left: 25px;
        }
        @media (max-width: 395px) {
            padding-left: -1px;
        }
    }
    @media (max-width: break-points.$sm) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 104px;
    padding-right: 23px;
}
}
.headWrapper{
    display: flex;
    flex-direction: column;
   
    @media (max-width: break-points.$sm) {
        flex-direction: column; 
    }
    @media (max-width: 365px) {
        font-size: 23px;
        width: max-content; 
    }
}
.system{
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 68px;
@media (max-width: 1150px) {
    gap: 48px;
}
@media (max-width: break-points.$lg)  {
    gap: 40px;
}
@media (max-width: break-points.$md) {
    gap: 19px;
}
@media (max-width: break-points.$sm) {
    gap: 17px;
    margin-top: 138px;
}
}
.Logo{
    display: flex;
    gap: 0.2rem;
    cursor: pointer;
    svg {
        width: 78px;
        height: 78px;

    @media (max-width: break-points.$md) {
        width: 60px;
        height: 60px;
        margin-top: 5px;
    }
}
    
}
.LogoHeading{
display: flex;
margin-top: 10px;
width: 358px;
height: 56px;
flex-direction: column;
justify-content: center;
flex-shrink: 0;
color: #443BB0;
-webkit-text-stroke-width: 5;
-webkit-text-stroke:1.8px #FFF;
font-family: Raleway;
font-size: 45px;
font-style: normal;
font-weight: bolder;
line-height: normal;
@media (max-width: 1150px) {
    font-size: 40px;
}
@media (max-width: break-points.$lg) {
    font-size: 36px;
}
@media (max-width: break-points.$md) {
    font-size: 30px;
    -webkit-text-stroke:1.3px #FFF;
}
@media (max-width: break-points.$sm) {
    font-size: 28px;
    -webkit-text-stroke:1px #FFF;
    width: auto;
}
@media (max-width: 365px) {
    font-size: 22px;
    width: auto;
}
}
.Heading{
display: flex;
height: 76px;
flex-direction: column;
justify-content: center;
align-self: stretch;
font-family: Raleway;
font-size: 80px;
font-style: normal;
font-weight: 700;
line-height: 24px;
&.light {
    color: #232323;
  }

  &.dark {
    color: white;
  }
  @media (max-width: 1350px) {
    font-size: 70px;
}
@media (max-width: 1150px) {
    font-size: 53px;
}
@media (max-width: break-points.$lg)  {
    font-size: 40px;
}
@media (max-width: break-points.$md) {
    font-size: 35px;
}
@media (max-width: break-points.$sm) {
    font-size: 37px;
}
@media (max-width: 365px) {
    font-size: 27px;
}
}
.text{
width: 683px;
font-family: Raleway;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: 47px;
padding-top: 70px;
&.light {
        color: #232323;
  }

  &.dark {
    color: white;
  }
@media (max-width: 1150px) {
    font-size: 24px;
    padding-top: 50px;
    width: 560px;
}
@media (max-width: 935px) {
    font-size: 20px;
    padding-top: 40px;
    width: 460px;
}
@media (max-width: 820px) {
    font-size: 16px;
    padding-top: 30px;
    width: 360px;
    line-height: 30px;
}
@media (max-width: break-points.$md) {
    font-size: 16px;
    padding-top: 20px;
    width: 360px;
    line-height: 30px;
    font-weight: 200;
}
@media (max-width: break-points.$sm) {
    font-size: 16px;
    line-height: 25px;
    padding-top: 20px;
}
@media (max-width: 365px) {
    font-size: 11px;
        line-height: 22px;
        width: 245px;
        padding-top: 11px;
}
}
.iconWrapper {
    margin-top: 165px;
    margin-left: 40px;
&.dark{
    svg{
        // width: 470px;
        width: 490px;
        height: 498px;
        margin-top: 42px;
       
        @media (max-width: 1150px) {
            margin-top: 27px;
            width: 404px;
        }
        @media (max-width: break-points.$lg) {
            width: 331px;
            margin-left: -9px;
            margin-bottom: 13px;
        }
        @media (max-width: 820px) {
            margin-left: -83px;
        }
        @media (max-width: break-points.$md) { 
            padding-right: 23px;
            width: 255px;
            margin-bottom: 61px;
            // margin-left: -122px;
        }
        @media (max-width: break-points.$sm) {
        margin-left: 13px;
        width: 180px;
        margin-top: -22rem !important;
        }
        @media (max-width: 365px) {
            width: 160px;
        }
    }
}
&.light{
    svg {
        width: 490px;
        height: 498px;
        margin-left: -44px;
        @media (max-width: 1150px) {
            width: 404px;
        }
        @media (max-width: break-points.$lg) { 
            width: 331px;
            margin-left: -9px;
            margin-bottom: 40px;
        }
        @media (max-width: 820px) {
            margin-left: -83px;
        }
        @media (max-width: break-points.$md) {
            width: 255px;
            margin-bottom: 71px;
            margin-left: -122px;
        }
        @media (max-width: break-points.$sm) {
            width: 180px;
        margin-top: -22rem !important;
        margin-left: -27px;
        }
        @media (max-width: 365px) {
            width: 160px;
        }
        }
  }
}