@use "../../../../../../../scss/variables";
@use "../../../../../../../scss/colors";
@use "../../../../../../../scss/break-points";

.container {
    width: 100%;
    height: 100%;
    box-sizing: inherit;
    display: flex;
    flex-direction: column;
    position: relative;

    .headSection{
        // height: 60%;
        justify-content: center;
    }

    .newChatBtn {
        width: 47px;
        height: 47px;
        padding: 8px 0;
        border-radius: 40px;

        &.light{
            background: rgba(124, 77, 255, 0.5);
        }

        &.dark{
            background: none;
        }

        .btnContent {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
            flex-shrink: 0;
            color: colors.$darkModeText;

            svg {
                & path {
                    stroke: colors.$darkModeText;
                }
            }

            .text {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }

    .content {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        .emptySearchMessage {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: colors.$darkModeText;

        }
    }

    .footer {
        width: 100%;
        padding: 18px 0 10px 0;
        border-top: 1px solid colors.$chatFooterBorderTop;
        position: absolute;
        bottom: 0px;

        .itemContainer {
            cursor: pointer;
            display: flex;
           justify-content: center;
            color: colors.$darkModeText;
            margin-bottom: 7px;

        }
    }

    .Hidefootersetting {
        display: none;

        @media (max-width:break-points.$md) {
            display: block;
        }

    }

    .themeallow {
        @media (max-width:break-points.$md) {
            display: none;
        }
    }
}

@keyframes moveToRight {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
    }
}