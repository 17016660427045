@use "../../scss/colors";

.label {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 32px;
}

.input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(92.31deg, colors.$colorLineargrandient 0%, colors.$colorLineargrandient2 100%);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 32px;

  &.chat{
    background: #c2c2c2;
  }

  &::before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;                      
    background-color: colors.$white;
    transition: 0.4s;
    border-radius: 50%;
  }

  &::after {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: colors.$white;
    transition: 0.4s;
    border-radius: 50%;
  }
}

.iconSlider {
  background: colors.$white;

  &::before {
    background-image: url("../../../public/icons/moon.svg");
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
      0px 1px 2px rgba(91, 104, 113, 0.32);
  }

  &::after {
    background-image: url("../../../public/icons/sun-active.svg");
    background-position: center;
    background-repeat: no-repeat;
  }
}

.input:checked + .slider {
  background: linear-gradient(92.31deg, colors.$colorLineargrandient 0%, colors.$colorLineargrandient2 100%);
}

.iconInput:checked + .slider {
  background: colors.$white;
  border: 1px solid colors.$primaryColor;
}

.input:focus + .slider {
}

.input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.input:checked + .slider::after {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
