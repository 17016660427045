@use "../../../../../../scss/break-points";

.icon {
  max-width: 32px;
  max-height: 32px;
  min-width: 32px;
  min-height: 32px;

  @media (max-width: 576px) {
    max-width: 24px;
    max-height: 24px;
    min-width: 24px;
    min-height: 24px;
    // margin-top: 4px;
  }
}
