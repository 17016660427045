@use "../../../../scss/colors";
@use "../../../../scss/break-points";

.button {
  padding: 0;
  margin: 0;
  border-radius: 8px;
  background-color: transparent;
  opacity: 0.6;

  &.light {
    svg {
      & path {
        stroke: colors.$textColorLight;
      }
    }
  }

  &.dark {
    svg {
      & path {
        stroke: colors.$white;
      }
    }
  }

  &.focused {
    opacity: 1;
    svg {
      & path {
        stroke: colors.$primaryColor;
      }
    }
  }

  &.darkModeFocused {
    opacity: 1;
    svg {
      & path {
        stroke: colors.$darkModeText;
      }
    }
  }

  &.disabled {
    opacity: 0.6;
    cursor: auto;
  }
}
