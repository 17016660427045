@use "../../../../../../../scss/colors";

.menuContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.overflowContainer {
  max-height: 169px;
  overflow-y: auto;
  padding: 12px 0;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}

.menuItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 12px;
  background-color: transparent;

  &.light {
    &.isSelected {
      background-color: rgba(colors.$white, 0.7);
    }
  }

  &.dark {
    &.isSelected {
      background-color: rgba(colors.$chatItembackground, 0.7);
    }
  }

  .icon {
    width: 15px;
    height: 15px;
    margin-right: 12px;

    &.imageIcon {
      margin-top: -8px;
    }
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-left: 13px;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
}
