@use "../../../scss/variables";
@use "../../../scss/colors";
@use "../../../scss/z-indices";
@use "../../../scss/break-points";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap");

.backdrop {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(25px);
  background-color: rgba(colors.$black, 0.3);
  -moz-background-color: rgba(colors.$black, 0.3);
  pointer-events: auto;
  z-index: z-indices.$backdrop;

  @media (max-width: break-points.$md) {
    display: block;
  }

  @media (max-width: break-points.$sm) {
    display: block;
  }
}

.mainContainer {
  width: variables.$sidebar;
  height: 100%;
  position: fixed;
  z-index: z-indices.$sidebar;
  transition: transform variables.$sb-transition ease;
  transform: translateX(-310px);

  &.isOpen {
    transform: translateX(0);
  }

  &.light {
    background: linear-gradient(175deg, colors.$lightModelineargradient1 1.08%, colors.$lightModelineargradient2 34.37%, colors.$lightModelineargradient3 67.16%, colors.$lightModelineargradient4 101.96%);

  }

  &.dark {
    background: linear-gradient(172deg, colors.$darkModeLineargradient1 -3.18%, colors.$darkModeLineargradient2 30.31%, colors.$darkModeLineargradient3 61.53%, colors.$darkModeLineargradient4 95.62%)
  }

  &.setting {
    background: colors.$settingSideBarLightMode 
  }

  &.settingdark {
    background: colors.$mainViewDarkMode;
  }
  @media (min-width: 775px) {
  &.smallmainContainer{
    width: variables.$miniSidebar;
      height: 100%;
      position: fixed;
      z-index: z-indices.$sidebar;
      transition: transform variables.$sb-transition ease;
      transform: translateX(0px);
  }
}                
}

.container {
  width: variables.$sidebar-box;
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  position: fixed;
  z-index: z-indices.$sidebar;
  transition: transform variables.$sb-transition ease;
  transform: translateX(-300px);
  padding: 25px 15px variables.$pagePadding 15px;
  margin: 15px;
  border-radius: 20px;
  background: colors.$sideBarBackground;
  box-shadow: 0px 620px 174px 0px rgba(46, 55, 127, 0.00), 0px 397px 159px 0px rgba(46, 55, 127, 0.01), 0px 223px 134px 0px rgba(46, 55, 127, 0.05), 0px 99px 99px 0px rgba(46, 55, 127, 0.09), 0px 25px 55px 0px rgba(46, 55, 127, 0.10);

  &.isOpen {
    transform: translateX(0);
  }

  &.darks{
    background: rgba(255,255,255,0.1);
  }

  &.settingdark {
    background: colors.$darkModeSetting;
    box-shadow: none;
  }

  @media (min-width: 775px) {
    &.smallmainContainer{
      width:  variables.$minisidebar-box;
        height: 100%;
        position: fixed;
        z-index: z-indices.$sidebar;
        transition: transform variables.$sb-transition ease;
        transform: translateX(0px);
    }
  }         
  

  @media (max-width: break-points.$md) {
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: break-points.$sm) {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
  }

  .header {
    height: variables.$sidebar-header-height;

    .logoHeader {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      &.isOpen{
        gap: 0;
        justify-content: center;
      }

      .logoButton {
        background-color: transparent;
        padding: 0;
        margin: 0;

        @media (max-width:break-points.$md) {
          width: 100%
        }

        .logoTextsmall {
          display: none;

          @media (max-width:break-points.$md) {
            display: block;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: colors.$darkModeText;
          }

          .mainsidehead {
            display: none;

            @media (max-width:break-points.$md) {
              display: block;
            }

            .sidebarhead {
              @media (max-width:break-points.$md) {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              }
            }
            .smallLogoText{
              background: radial-gradient(61.07% 143.16% at 18.26% 69.36%, colors.$sidebarLogoText 0%, colors.$sidebarLogoText2 100%);
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }

        .logoText {
          font-family: Montserrat;
          font-size: 28px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          color: colors.$darkModeText;

          @media (max-width:break-points.$md) {
            display: none;
          }

          .mainsidehead {
            display: none;

            @media (max-width:break-points.$sm) {
              display: block;
            }

            .sidebarhead {
              @media (max-width:break-points.$sm) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 62px;
              }
            }
          }

          &.setting {
            background: radial-gradient(61.07% 143.16% at 18.26% 69.36%, colors.$sidebarLogoText 0%, colors.$sidebarLogoText2 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }

      .button {
        border: none;
        margin: 0;
        padding: 0;
        background-color: transparent;

        svg {
          & path {
            fill: colors.$darkModeText;
          }
        }

        &.setting{
          svg {
            & path {
              fill: colors.$primaryColor;
            }
          }
        }
      }
    }
  }

  .content {
    width: 100%;
    height: calc(100% - variables.$sidebar-header-height);
    background-color: transparent;
    position: relative;

    .lgContent {
      display: block;
      height: 100%;
      margin-top: 10px;

      @media (max-width: break-points.$md) {
        display: block;
      }

      @media (max-width: break-points.$sm) {
        display: block;
      }
    }

    .mdContent {
      display: none;

      @media (max-width: break-points.$md) {
        display: none;
        height: 100%;
      }

      @media (max-width: break-points.$sm) {
        display: none;
      }
    }

    .smContent {
      display: none;

      .footer {
        width: 100%;
        padding: 18px 0;
      }

      @media (max-width: break-points.$sm) {
        display: block;
        height: 100%;
      }
    }
  }
}