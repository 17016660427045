@use "../../../../scss/variables";
@use "../../../../scss/colors";
@use "../../../../scss/fonts";
@use "../../../../scss/break-points";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  @media (max-width: break-points.$sm) {
    gap: 30px;
  }

  .userInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 60px;

    &.light {
      color: colors.$textColorLightMode;
    }

    &.dark {
      color: colors.$white;
    }

    @media (max-width: break-points.$sm) {
      width: 100%;
      margin-top: 40px;
    }

    .userAvatar {
      min-width: 80px;
      min-height: 80px;
      font-size: 24px;
      border-radius: 50%;
      margin-bottom: 16px;

      @media (max-width: break-points.$sm) {
        min-width: 60px;
        min-height: 60px;
        font-size: 18px;
        margin-bottom: 10px;
      }
    }

    .userName {
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 4px;

      @media (max-width: break-points.$sm) {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .email {
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      opacity: 0.9;

      @media (max-width: break-points.$sm) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .teamDDContainer {
      width: 368px;

      @media (max-width: break-points.$sm) {
        width: 100%;
      }
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;

      .usageSmall {
        display: none;
        &.lightss {
          color: colors.$textColorLight;  
        }
        &.darkss {
          color: colors.$white;
        }
        @media (max-width: break-points.$sm) {
          display: block;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 10px;
          left: 0px;
          width: 100%;
      }
    }
      .displaylang {
        display: none;
        &.lightss {
          color: colors.$textColorLight;  
        }
        &.darkss {
          color: colors.$white;
        }
        @media (max-width: break-points.$sm) {
          display: block;
          margin-top: 20px;
          font-size: 16px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 4px;
          width: 100%;


        }
      }

    @media (max-width: 1050px) {
      flex-direction: column;
      gap: 16px;
    }

    @media (max-width: break-points.$sm) {
      width: 100%;
      gap: 10px;
    }

    .col {
      border-radius: 20px;
      width: 368px;
      padding: 20px;
      display: flex;
      align-items: center;
      background-color: rgba(#C5C5C5, 0.04);

      .usageSmall {
        display: none;

        @media (max-width: break-points.$sm) {
          display: block;
          margin-top: 20px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 10px;
          left: 0px;
          

        }
      }
      &.lights {
        background-color: rgba(colors.$textColorLightMode, 0.04);
        color: colors.$textColorLight;
        @media (max-width:break-points.$sm) {
          background-color: colors.$darkModeText;
        }
      }

      &.darks {
        background-color: rgba(colors.$darkModeText, 0.08);
        color: colors.$white;
        @media (max-width:break-points.$sm) {
          background-color: colors.$darkModeBackground;
        }
      }

      &.light {
        color: colors.$textColorLightMode;
      }

      &.dark {
        color: colors.$darkModeText;
      }

      @media (max-width: break-points.$sm) {
        background: transparent;
        width: 100%;
        padding: 10px 10;
      }

      .keyboardIcon {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(92.31deg, colors.$colorLineargrandient 0%, colors.$colorLineargrandient2 100%);
        border-radius: 12px;
      }

      .deftgptIcon {
        width: 50px;
        height: 50px;
      }

      .colContent {
        display: flex;
        flex-direction: column;
        margin-left: 16px;

        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 2px;
        }
      }

      .usage {
        width: 100%;

        .h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 10px;

          @media (max-width: break-points.$sm) {
            font-size: 14px;
            display: none;
          }
        }

        .svg {
          border-top: 1px solid #454646;
          margin-top: 4px;
          margin-bottom: 4px;
        }

        .usageRow {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:first-child {
            margin-bottom: 2px;
          }

          .number {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            font-family: fonts.$regularFont;

            @media (max-width: break-points.$sm) {
              font-size: 14px;
            }
          }
        }
      }

      .language {
        width: 100%;

        .h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;

          @media (max-width: break-points.$sm) {
            font-size: 14px;
            display: none;
          }
        }

        .select {
          height: 44px;
        }
      }
    }
    .cols {
      border-radius: 20px;
      width: 368px;
      padding: 20px;
      display: flex;
      align-items: center;
      background-color: rgba(#C5C5C5, 0.04);
      @media (max-width:break-points.$sm) {
        padding: 0px;
      }

      .usageSmall {
        display: none;

        @media (max-width: break-points.$sm) {
          display: block;
          margin-top: 20px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 10px;
          left: 0px;
          

        }
      }
      &.lights {
        color: colors.$textColorLightMode;
        @media (max-width:break-points.$sm) {
          background-color: colors.$darkModeText;
        }
      }

      &.darks {
        color: colors.$darkModeText;
        @media (max-width:break-points.$sm) {
          background-color: colors.$darkModeBackground;
        }
      }

      &.light {
        background-color: rgba(colors.$textColorLightMode, 0.04);
        color: colors.$textColorLight;
      }

      &.dark {
        background-color: rgba(colors.$darkModeText, 0.08);
        color: colors.$white;
      }

      @media (max-width: break-points.$sm) {
        background: transparent;
        width: 100%;
        padding: 10px 10;
      }

      .keyboardIcon {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(92.31deg, colors.$colorLineargrandient 0%, colors.$colorLineargrandient2 100%);
        border-radius: 12px;
      }

      .deftgptIcon {
        width: 50px;
        height: 50px;
      }

      .colContent {
        display: flex;
        flex-direction: column;
        margin-left: 16px;

        .title {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 2px;
        }
      }

      .usage {
        width: 100%;

        .h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 10px;

          @media (max-width: break-points.$sm) {
            font-size: 14px;
            display: none;
          }
        }

        .svg {
          display: none;

          @media (max-width:break-points.$sm) {
            display: block;

          }
        }

        .usageRow {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:first-child {
            margin-bottom: 2px;
          }

          .number {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            font-family: fonts.$regularFont;

            @media (max-width: break-points.$sm) {
              font-size: 14px;
            }
          }
        }
      }

      .language {
        width: 100%;

        .h4 {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 16px;

          @media (max-width: break-points.$sm) {
            font-size: 14px;
            display: none;
          }
        }

        .select {
          height: 44px;
        }
      }
    }
  }

  .plan {
    border-radius: 20px;
    width: 760px;
    height: 90px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.light {
      color: colors.$textColorLightMode;
    }

    &.dark {
      color: colors.$darkModeText;
    }

    @media (max-width: 1050px) {
      width: 368px;
      height: auto;
      flex-direction: column;
      gap: 16px;
    }

    @media (max-width: break-points.$sm) {
      width: 100%;
      background: transparent;
      padding: 15px 0;
      margin-top: 7px;
    }

    .planbox {
      padding: 19px 16px;
      background: rgba(124, 77, 255, 0.1);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @media (max-width: 1050px) {
        width: 100%;
      }

      @media (max-width: break-points.$sm) {
        width: auto;
        padding: 10px 12px;
      }

      .text {
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: colors.$primaryColor;

        &.light {
          opacity: 1;
        }

        &.dark {
          opacity: 0.4;
        }

        @media (max-width: break-points.$sm) {
          opacity: 1;
        }
      }

      .count {
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        display: inline-block;
        margin: 0 4px;

        @media (max-width: break-points.$sm) {
          margin: 0;
        }
      }
    }

    .planTypeContainer {
      width: 368px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media (max-width: break-points.$lg) {
        width: 100%;
        padding: 0 12px 10px;
      }

      .playType {
        display: flex;
        flex-direction: column;

        .planValue {
          display: inline-block;
          margin-top: 4px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
        }
      }

      .upgradeButton {
        background: linear-gradient(92.31deg, colors.$colorLineargrandient 0%, colors.$colorLineargrandient2 100%);
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 16px 10px 14px;
        gap: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
       

        &.light{
          color: colors.$darkModeText;
        }
        &.dark{
          color: colors.$textColorLightMode;
        }

        &:hover {
          cursor: pointer;
          background: linear-gradient(92.31deg, colors.$colorLineargrandient2 0%, colors.$colorLineargrandient 100%);
        }
      }
    }
  }
}

// Account
.emailContainer {
  position: relative;
  display: flex;
  align-items: center;
 gap: 1rem;

  .dropdown {
    position: relative;
    display: inline-block;
  }
}

.menuItemEdit {
  color: #2A2831;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  gap: 0.5rem;
}

.menuItemDel {
  color: #DF3A32;
font-size: 10px;
font-style: normal;
font-weight: 500;
line-height: 28px; 
display: flex;
gap: 0.5rem;
cursor: pointer;

  .icon {
    margin-right: 8px;
  }
}

.rc-dropdown-hidden {
  display: none !important;
}

.rc-dropdown {
  display: block !important;
}
.DropDownbody {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 180px;
  overflow-y: auto;
}