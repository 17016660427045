@use "../../../../scss/colors";
@use "../../../../scss/break-points";

.newContentContainer {
  padding: 50px 82px 20px 82px;

  @media (max-width:break-points.$sm) {
    padding: 30px 30px 20px 30px;
  }

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    background-color: colors.$mainViewDarkMode;
    color: colors.$white;
  }

  .logo {
    margin-bottom: 24px;
  }

  .h1 {
    font-size: 58px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 11px;
    background: linear-gradient(117.59deg, colors.$sidebarLogoText2 14.87%, colors.$sidebarLogoText 92.39%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    opacity: 0.9;
    width: fit-content;
    @media (max-width: break-points.$md) {
      font-size: 40px;
    }
  }

  .message {
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: colors.$chatMessage;
    @media (max-width: break-points.$md) {
      font-size: 20px;
    }
  }

  .buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 24px;
    margin-top: 60px;

    @media (max-width:break-points.$sm) {
      margin-top: 30px;
      padding: 0 1px;
    }

    .button {
      width: 236px;
      min-height: 245px;
      border-radius: 20px;
      padding: 0;
      margin: 0;
      margin-top: 12px;
      cursor: pointer;

      @media (max-width:break-points.$sm) {
        width: 100%;
        min-height: 135px;
        border-radius: 12px;
      }

      &.light {
        background: colors.$darkModeText;
        box-shadow: 0px 206px 58px 0px rgba(120, 120, 120, 0.00), 0px 132px 53px 0px rgba(120, 120, 120, 0.01), 0px 74px 45px 0px rgba(120, 120, 120, 0.05), 0px 33px 33px 0px rgba(120, 120, 120, 0.09), 0px 8px 18px 0px rgba(120, 120, 120, 0.10);
        color: colors.$textColorLightMode;
      }

      &.dark {
        background: colors.$darkModeSetting;
        color: colors.$darkModeText;
      }

      .content {
        position: relative;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        padding: 24px 0;
        // gap: 25px;
        height: 245px;

        @media (max-width:break-points.$sm) {
          display: flex;
          flex-direction: row;
          padding: 14px 30px;
          height: 135px;
        }

        @media (max-width:360px) {
          padding: 14px 15px;
        }

        @media (max-width:331px) {
          padding: 10px 6px;
        }

        .modelIconContainer {
          width: 32px;
          height: 32px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          position: absolute;
          bottom: 20px;
          right: 20px;

          @media (max-width:break-points.$sm) {
            width: 30px;
          }
        }

        .icon {
          position: absolute;
          right: 20px;
          bottom: 20px;
          &.light {
            svg {
              & path {
                fill: colors.$textColorLightMode;
              }

              & rect {
                stroke: colors.$textColorLightMode;
              }
            }
          }

          &.dark {
            svg {
              & path {
                fill: colors.$darkModeText;
              }

              & rect {
                stroke: colors.$darkModeText;
              }
            }
          }
        }

        .text {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          opacity: 0.9;
          text-align: left;
          padding: 0px 25px;
        }
      }
    }
  }
}

.dropzone {
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &.light {

    &.isDragOver {
      box-shadow: 0px 0px 0px 0px rgba(colors.$textColorLight, 0.05),
        1px 2px 5px 0px rgba(colors.$textColorLight, 0.05),
        6px 7px 9px 0px rgba(colors.$textColorLight, 0.04),
        13px 16px 12px 0px rgba(colors.$textColorLight, 0.03),
        23px 28px 15px 0px rgba(colors.$textColorLight, 0.01),
        37px 43px 16px 0px rgba(colors.$textColorLight, 0);
    }
  }

  &.dark {
    @media (max-width: break-points.$sm) {
      background-color: rgba(217, 217, 217, 0.04);
    }

    &.isDragOver {
      box-shadow: 0px 0px 0px 0px rgba(colors.$white, 0.05),
        1px 2px 5px 0px rgba(colors.$white, 0.05),
        6px 7px 9px 0px rgba(colors.$white, 0.04),
        13px 16px 12px 0px rgba(colors.$white, 0.03),
        23px 28px 15px 0px rgba(colors.$white, 0.01),
        37px 43px 16px 0px rgba(colors.$white, 0);
    }
  }

  @media (max-width: break-points.$sm) {
    border-radius: 10px;
  }

  .uploadIcon {
    width: 35px;
    height: 47px;

    @media (max-width: break-points.$sm) {
      width: 26px;
      height: 34px;
    }
  }

  .message {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    word-break: break-all;
    margin-top: 20px;
    color: colors.$lightThemeColor;

    @media (max-width: break-points.$sm) {
      margin-top: 0;
      font-size: 14px;
    }
  }

  .validationText {
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    word-break: break-all;
    color: colors.$lightThemeColor;

    @media (max-width: break-points.$sm) {
      font-size: 11px;
      margin-top: 0;
    }
  }

  .clickHereText {
    color: colors.$primaryColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 50px;

    @media (max-width: break-points.$sm) {
      font-size: 13px;
      margin-top: 15px;
    }
  }
}