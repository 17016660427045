@use "../../../../scss/colors";

.heading {
  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}

.duration {
  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
    opacity: 0.7;
  }
}

.Pricecardwhite {
  border-radius: 20px;
  padding: 20px;
  // background-color: #f5f5f5;
  color: colors.$textColorLight;
}

.Pricecard {
  border-radius: 20px;
  // background-color: #262627;
  color: colors.$white;
  padding: 20px;
}

// .setFrequency {
//   color: rgba(42, 40, 49, 0.8);

//   font-family: Raleway;
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 24px;
//   margin-top: 5px;
// }
// .setFrequencydark{
//   color: #FFF;

//   font-family: Raleway;
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 400;
//   line-height: 24px;
//   margin-top: 5px;
// }
// .setFrquencyheaddark{
//   color: #FFF;
//   margin-top: 12px;
//   font-family: Raleway;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 24px;
// }

// .setFrquencyhead {
//   color: rgba(42, 40, 49, 0.8);
//   margin-top: 12px;
//   font-family: Raleway;
//   font-size: 14px;
//   font-style: normal;
//   font-weight: 700;
//   line-height: 24px;
// }
