@use "../../../scss/colors";
@use "../../../scss/variables";
@use "../../../scss/z-indices";

.container {
  display: inline;
  position: relative;
}

.tooltip {
  font-size: 12px;
  line-height: initial;
  padding: variables.$spacing-md;
  border-radius: 5px;
  max-width: 300px;
  z-index: z-indices.$tooltip;
  box-shadow: 0 2px 4px rgba(colors.$dark-blue, 0.12);
  background: colors.$themeActiveDark;
  color: colors.$white;
  text-align: justify;
  word-break: break-word;

  a {
    color: rgba(colors.$white, 0.5);
  }

  &.light {
    background: rgba(colors.$white, 0.9);
    color: colors.$black;

    a {
      color: colors.$blue;
    }
  }

  &.prompt {
    position: relative;
    background:  #EFEAFF;
    border-radius: 0.4em;
    padding: 10px 20px;
    display: inline-block;
    color: colors.$primaryColor;
    padding: variables.$spacing-md;
    margin-left: 35px;
    margin-top: -20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  
  }

  &.prompt::after {
    content: '';
    position: absolute;
    bottom: -15px;
    /* Adjust the position to match your design */
    left: 28px;
    /* Adjust the position to match your design */
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top-color: #EFEAFF;
    border-bottom: 0;
    margin-left: -15px;
  }

  &.promptDark{
    position: relative;
    background: #252526;
    border-radius: 0.4em;
    padding: 10px 20px;
    display: inline-block;
    color: colors.$darkModeText;
    padding: variables.$spacing-md;
    margin-left: 35px;
    margin-top: -20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  
  }

  &.promptDark::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 28px;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top-color: #252526;
    border-bottom: 0;
    margin-left: -15px;
  }

}