@use "../../../../scss/variables";
@use "../../../../scss/break-points";
@use "../../../../scss/z-indices";
@use "../../../../scss/colors";

.footer {
  border-radius: 0 0 20px 20px;
  height: variables.$chatFooter;
  padding: 20px 82px;
  box-sizing: border-box;
  position: fixed;
  bottom: variables.$pagePadding;
  right: variables.$pagePadding;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: calc(100% - (variables.$pagePadding * 2));
  transition: width variables.$sb-transition ease;

  &.light {
    background-color: colors.$white;
  }

  &.dark {
    background-color: colors.$mainViewDarkMode;
  }

  &.FileFooter {
    height: 84px;
  }

  &.isSidebarOpen {
    width: calc(100% - (variables.$sidebar + variables.$pagePadding));
    transition: width variables.$sb-transition ease;

    @media (max-width: break-points.$md) {
      width: 100%;
    }
  }

  &.isSideBarClose {
    @media (min-width: 775px) {
      width: calc(100% - (variables.$miniSidebar + variables.$pagePadding));
      transition: width variables.$sb-transition ease;
    }
  }

  &.ReceiveShareChatFooter {
    width: calc(100% - 70px);
    height: 100px;

    @media (max-width:450px) {
      height: 120px;
    }
  }

  &.shareChat {
    background-color: colors.$shareChatLight;
  }

  &.shareChatFooter {
    padding-right: 10px;
    padding-left: 10px;

    @media (max-width: break-points.$sm) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  @media (max-width: break-points.$lg) {
    padding: 20px;
  }

  @media (max-width: break-points.$md) {
    width: calc(100% - variables.$pagePadding * 2);
  }

  @media (max-width: break-points.$sm) {
    padding: 12px 0px;
    height: variables.$chatFooterSM;
  }

  &.IGFooter {
    height: variables.$IGChatFooter;

    @media (max-width: break-points.$sm) {
      height: variables.$IGChatFooterSM;
    }
  }

  &.IChatFooter{
    height: 118px;

    @media (max-width: break-points.$sm) {
      height: variables.$IGChatFooterSM;
    }

    &.uplodingFile {
    height: variables.$IGChatFooter + 53px;
    }
  }

  .IGOptionsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 20px;
    right: 0;
    padding: 0 82px;
    transition: padding variables.$sb-transition ease;

    &.light {
      color: colors.$textColorLightMode;
      opacity: 0.9;
    }

    &.dark {
      color: colors.$white;
    }

    &.isSidebarOpen {
      padding: 0 82px;
      transition: padding variables.$sb-transition ease;
    }

    @media (max-width: break-points.$lg) {
      top: 20px;
      padding: 0 20px;
    }

    @media (max-width: break-points.$sm) {
      top: 12px;
      padding: 0 0px;
    }

    .IGOptions {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      @media (max-width: break-points.$sm) {
        gap: 10px;
      }

      .option {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7px;

        @media (max-width: break-points.$sm) {
          gap: 5px;
        }

        .label {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px;

          @media (max-width: break-points.$sm) {
            font-size: 12px;
          }

          @media (max-width: 440px) {
            display: none;
          }
        }
      }
    }

    .imageCredits {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      padding: 0px 76px 0 0;


      @media (max-width: break-points.$sm) {
        font-size: 12px;
        padding: 0;
      }
    }
  }

  .form {
    width: 100%;
    display: flex;
    align-items: flex-end;
    gap: 24px;
    z-index: 9998;

    @media (max-width: break-points.$md) {
      gap: 16px;
    }

    @media (max-width: break-points.$sm) {
      gap: 10px;
    }
  }
}

.chatfooterPromptbtn {
  margin-bottom: 7px;

  @media (max-width: break-points.$sm) {
    margin-bottom: 2px;
  }
}

.chatfooterbtn {
  cursor: pointer;
  background-color: rgba(124, 77, 255, 0.1);
  width: 55px;
  height: 52px;
  border-radius: 100%;

  @media (max-width: break-points.$sm) {
    margin-bottom: 5px;
    width: 42px;
  height: 47px;
  }

  &.imagegeneration{
    background-color: rgba(124, 77, 255, 1);
    width: 52px;
    height: 52px;

    @media (max-width: break-points.$sm) {
      width: 40px;
      height: 45px;
    }
  }
}

.imagegenerationIcon{
@media (max-width: break-points.$sm) {
  height: 45px;
  svg{
    height: 20px;
    width: 20px;
  }
}
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  // height: 100%;

  &.ImaePreview{
    width: 100px;
    height: auto;
  }

  &.imagesPrev{
    padding: 20px;

    @media (max-width: break-points.$sm) {
      padding: 27px 10px 0px 10px;
    }
  }
}

.svgCrossIcon{
  display: flex;
  cursor: pointer;
  float: right;
  position: absolute;
  right: -15px;
  top: -20px;
}


.IGOptionContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 20px;
  right: 0;
  padding: 0 163px 0px 82px;
  transition: padding variables.$sb-transition ease;

  &.light {
    color: colors.$textColorLightMode;
    opacity: 0.9;
  }

  &.dark {
    color: colors.$white;
  }

  &.isSidebarOpen {
    padding: 0 163px 0 82px;
    transition: padding variables.$sb-transition ease;
  }

  @media (max-width: break-points.$lg) {
    top: 20px;
    padding: 0 93px 0 20px;
  }

  @media (max-width: break-points.$sm) {
    top: 12px;
    padding: 0 50px 0 0;
  }
  @media (max-width: 375px) {
    top: 6px;
    padding: 0 50px 0 0;
  }

  .IGOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    @media (max-width: break-points.$sm) {
      gap: 10px;
    }
  }

  .imageCredits {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;

    @media (max-width: break-points.$sm) {
      font-size: 12px;
    }
  }
}

.IIGOptionContainer {
  width: 100%;
  justify-content: flex-start;
  position: absolute;
  top: 20px;
  right: 0;
  transition: padding variables.$sb-transition ease;

  &.light {
    color: colors.$textColorLightMode;
    opacity: 0.9;
  }

  &.dark {
    color: colors.$white;
  }

  &.isSidebarOpen {
    transition: padding variables.$sb-transition ease;
  }

  @media (max-width: break-points.$lg) {
    top: 20px;
    padding: 0 20px;
  }

  @media (max-width: break-points.$sm) {
    top: 12px;
    padding: 0 0px;
  }
  @media (max-width: 375px) {
    top: 6px;
    padding: 0 0px;
  }

  .IGOptions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    @media (max-width: break-points.$sm) {
      gap: 10px;
    }
  }

  .imageCredits {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;

    @media (max-width: break-points.$sm) {
      font-size: 12px;
    }
  }
}


.uplodingFile {
  height: variables.$IGChatFooter + 58px;
  }