@use "../../../../../scss/variables";
@use "../../../../../scss/colors";
@use "../../../../../scss/break-points";

.container {
  width: 100%;
  height: 100%;
  box-sizing: inherit;
  display: flex;
  flex-direction: column;
  position: relative;

  .newChatBtn {
    width: 140px;
    padding: 8px 0;
    border-radius: 40px;
    background: colors.$newChatbtn;

    &:hover {
      background: colors.$darkModeText;
      color: colors.$primaryColor;
      .btnContent {
        color: colors.$primaryColor;
        svg {
          & path {
            stroke: colors.$primaryColor;
          }
        }
      }
    }

    .btnContent {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
      flex-shrink: 0;
      opacity: 0.6;
      color: colors.$darkModeText;

      svg {
        & path {
          stroke: colors.$darkModeText;
        }
      }

      &:hover {
        color: colors.$primaryColor;
        svg {
          & path {
            stroke: colors.$primaryColor;
          }
        }
      }

      .text {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }

  .content {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255,0.1);
    }

    .emptySearchMessage {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: colors.$darkModeText;
    }
  }

  .footer {
    width: 100%;
    padding: 18px 0;
    border-top: 1px solid colors.$chatFooterBorderTop;

    .itemContainer {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      color: colors.$darkModeText;

      // svg {
      //   & path {
      //     fill: colors.$darkModeText;
      //   }
      // }

      .itemText {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }

      .usageTag {
        background-color: colors.$darkModeText;
        -moz-background-color: colors.$darkModeText;
        border-radius: 300px;
        padding: 1px 7px;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 22px;
        color: colors.$textColorLightMode;
      }
    }
  }

  .Hidefootersetting {
    display: none;

    @media (max-width: break-points.$md) {
      display: block;
    }
  }

  .themeallow {
    @media (max-width: break-points.$md) {
      display: none;
    }
  }
}

@keyframes moveToRight {
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}

.historyIconContainer{
  color: #FFF;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 8px;
  margin-left: -3px;
  }
  .deleteHistory{
    background-color: #DF3A32;
    border-radius: 50px;
    width: 23px;
    height: 23px;
    flex-shrink: 0;
    cursor: pointer;
  }
  .delChat{
    display: flex;
    gap: 1rem;
  }
