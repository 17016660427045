@use "../../../../scss/break-points";
@use "../../../../scss/colors";

.textAreaContainer {
  width: 100%;
  padding: 10px 0 10px 16px;
  display: flex;
  // align-items: center;
  border-radius: 25px;
  box-sizing: border-box;
  cursor: text;
  flex-direction: column;

  &.light {
    border: 1px solid colors.$searchLightMode;
    background: colors.$searchBackgroundLight;

    &.focus {
      background: colors.$searchfieldActive;
    }
    &.activeborder {
      border: none;
      background-color: colors.$searchfieldActiveLight;
      padding: 5px 0 5px 16px;

      @media (max-width: break-points.$sm) {
        padding: 4.5px 0 4.5px 12px;
      }    
     }

     &.file{
      max-height: 400px;
     }
  }

  &.dark {
    background-color: colors.$searchDarkMode;

    &.focus {
      background-color: colors.$searchfieldActive;
    }
    &.activeborder {
      border: none;
      background-color: colors.$searchfieldActivedark;
      padding: 5px 0 5px 16px;

      @media (max-width: break-points.$sm) {
        padding: 4.5px 0 4.5px 12px;
      }
     }
  }

  @media (max-width: break-points.$sm) {
    padding: 4.5px 0 4.5px 12px;
  }

  .textarea {
    width: 100%;
    resize: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    max-height: 50vh;
    height: 24px;
    padding-right: 16px;
    white-space: pre-line;
    margin-left: 5px;

    @media (max-width: break-points.$sm) {
      margin-left: 0px;
    }

    &.light {
      color: colors.$textColorLightMode;
    }

    &.dark {
      color: colors.$darkModeText;
    }

    @media (max-width: break-points.$sm) {
      font-size: 12px;
    }
  }

  .loaderContainer {
    width: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    @media (max-width: break-points.$sm) {
      width: 40px;
    }
  }

  .chatfooterbtn{
    margin-right: 10px;
  }
}

.fileDisplay {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 200px;
  color: black;
  position: relative;
  flex: 0 1 calc(30% - 10px); 
  margin: 5px; 
  box-sizing: border-box;

  @media (max-width: break-points.$sm) {
    flex: 0 1 calc(80% - 10px); 
    width: 190px;
  }

  @media (max-width: 340px) {
    flex: 0 1 calc(80% - 10px); 
    width: 170px;
    padding: 7px;
  }
}

.fileIcon img {
  width: 36px;
  height: 36px;
}

.fileInfo {
  flex-grow: 1;
  margin-left: 10px;
}

.fileName {
  font-size: 14px;
  font-weight: bold;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: break-points.$sm) {
    font-size: 12px;
    font-weight: bold;
    width: 78px;
  }
}

.fileType {
  font-size: 12px;
  color: gray;
}

.closeButton {
  color: white;
  cursor: pointer;
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  background-color: #71717A;
  border-radius: 50%;
  margin-bottom: 22px;
  padding: 3px;
}
.fileContainer {
  display: flex;
  flex-wrap: wrap;
  max-height: 150px;
  overflow-y: auto;
  padding-left: 24px;
  padding-top: 10px;

  @media (max-width: break-points.$sm) {
    padding-left: 0px;
  }
}

.loader{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid #EBEBEB;
}
.closeButtonLoader {
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
  padding: 3px;
}