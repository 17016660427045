@use "../../../scss/colors";

.switchButton {
  width: 64px;
  height: 32px;
  padding: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  border-radius: 300px;
  border: 1px solid rgba(colors.$darkModeText, 0.05);
  margin-bottom: -1px;

  &.dark {
    background-color: colors.$stopgenerateDark;
  }

  &.light {
    background-color: colors.$headerBackground;
  }

  .state {
    min-width: 24px;
    min-height: 24px;
    border-radius: 300px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      & path {
        stroke: #cfcfcf;
      }
    }

    &.isActiveLight {
      background-color: colors.$white;
      border: 1px solid rgba(colors.$darkModeText, 0.05);
      box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.32),
        0px 0px 1px 0px rgba(26, 32, 36, 0.32);

      svg {
        & path {
          stroke: colors.$primaryColor;
        }
      }
    }

    &.isActiveDark {
      background-color: colors.$themeActiveDark;
      box-shadow: 0px 1px 2px 0px rgba(91, 104, 113, 0.8),
        0px 0px 2px 1px rgba(26, 32, 36, 0.8);

      svg {
        & path {
          stroke: colors.$white;
        }
      }
    }
  }
}
