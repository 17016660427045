@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;

  .message {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    word-break: break-all;

    &.light {
      color: colors.$textColorLight;
    }

    &.dark {
      color: colors.$white;
    }

    @media (max-width: break-points.$sm) {
      font-size: 18px;
    }
  }
}

.uploadFail{
    width: 100%;
    height: 100%;
    padding: 10px;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.message {
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  word-break: break-all;
  opacity: 90%;
  color: colors.$docUploadFail;

  @media (max-width: break-points.$sm) {
    font-size: 16px;
  }
}
.validationText {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  word-break: break-all;
  color: colors.$darkModeText;
  margin-top: 5px;

  // &.dark{
  //   // opacity: 60%;
  // }

  @media (max-width: break-points.$sm) {
    font-size: 11px;
    margin-top: 0;
  }
}