@use "../../../../../scss/break-points";
@use "../../../../../scss/colors";

.menu {
  border-radius: 10px;
  padding: 8px 12px;
  opacity: 0.9;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 400;

  &.light {
    color: colors.$textColorLightMode;
    opacity: 0.9;

    svg {
      & path {
        stroke:colors.$textColorLightMode;
        opacity: 0.9;
      }
    }
  }

  &.dark {
    background-color: colors.$darkModeSetting;
    color: colors.$white;

    svg {
      & path {
        stroke: colors.$white;
      }
    }
  }

  @media (max-width: break-points.$md) {
    border-radius: 6px;
    padding: 6px 8px;
    gap: 10px;
  }

  .text {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    @media (max-width: break-points.$md) {
      font-size: 12px;
    }
  }
}

.menuItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  cursor: pointer;

  &.light {
    color: colors.$textColorLight;

    &.isSelected {
      background-color: rgba(colors.$white, 0.7);
    }
  }

  &.dark {
    color: colors.$white;

    &.isSelected {
      background-color: rgba(colors.$chatItembackground, 0.7);
    }
  }

  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    padding-left: 10px;

    @media (max-width: break-points.$md) {
      font-size: 12px;
      padding-left: 7px;
    }
  }
}
