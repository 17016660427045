@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.container {
  width: 100%;
  padding: 12px 40px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  @media (max-width: break-points.$lg) {
    padding: 12px 20px;
  }

  @media (max-width: break-points.$sm) {
    padding: 10px 31px;
  }
}

.leftContainer {
  width: 100%;
  display: flex;
  flex-direction: row;

  .assistanceIconAnimation {
    animation: rotateAnimation 1s linear infinite;
    border-radius: 10px;
    max-width: 32px;
    max-height: 32px;
    min-width: 32px;
    min-height: 32px;

    @media (max-width: 576px) {
      max-width: 24px;
      max-height: 24px;
      min-width: 24px;
      min-height: 24px;

    }
  }
}

.messageContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  justify-content: space-between;
  padding: 0 0 0 32px;

  &.chatEdit{
    width: 100%;
  }

  @media (max-width: break-points.$lg) {
    padding: 0 0 0 20px;
  }

  @media (max-width: break-points.$sm) {
    padding: 0 0 0 10px;
  }

  .fileText {
    white-space: pre-line;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    margin-top: 7px;
    -webkit-overflow-scrolling: touch;
    display: flex;
    gap: 15px;
    margin: 0 0 0 25px;
    overflow-x: auto;
    overflow-x: auto;
    // overflow-y: hidden; /* Prevent vertical overflow */
    white-space: nowrap;

    &.light {
      color: colors.$textColorLight;
    }

    &.dark {
      color: colors.$white;
    }

    @media (max-width: break-points.$sm) {
      font-size: 14px;
      line-height: 24px;
    }

    a {
      opacity: 0.9;
      color: colors.$primaryColor;

      .docIcon {
        margin-bottom: -6px;
        width: 24px;
        height: 24px;

        @media (max-width: break-points.$sm) {
          margin-bottom: -4px;
          width: 18px;
          height: 18px;
        }
      }

      .answerFile {
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &.light{
          color: colors.$textColorLightMode;
        }

        &.dark{
          color: colors.$lightThemeColor;
        }
      }
    }
  }
}

.actionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 9px;
  padding: 0 40px 12px 112px;

  .icon {
    cursor: pointer;

    svg {
      & path {
        fill: colors.$answerActionIcon;
      }
    }
  }

  @media (max-width: break-points.$lg) {
    padding: 0 20px 12px 80px;
  }

  @media (max-width: break-points.$sm) {
    padding: 0 15px 10px 57px;
  }
}

.questionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  padding: 0 40px 12px 112px;

  @media (max-width: break-points.$lg) {
    padding: 0 20px 12px 80px;
  }

  @media (max-width: break-points.$sm) {
    padding: 0 30px 10px 57px;
  }
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.fileText {
  white-space: pre-line;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }

  @media (max-width: break-points.$sm) {
    font-size: 14px;
    line-height: 24px;
  }

  a {
    opacity: 0.9;
    color: colors.$primaryColor;

    .docIcon {
      margin-bottom: -6px;
      width: 24px;
      height: 24px;

      @media (max-width: break-points.$sm) {
        margin-bottom: -4px;
        width: 18px;
        height: 18px;
      }
    }

    .answerFile {
      font-family: Raleway;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &.light{
        color: colors.$textColorLightMode;
      }

      &.dark{
        color: colors.$lightThemeColor;
      }
    }
  }
}

.EditButton{
  background-color: rgba(124, 77, 255, 0.1);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileMessageContainer {
  width: 100%;
  display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // justify-content: space-between;
  // padding: 0 0 0 69px;
  overflow-x: auto;

  &.chatEdit{
    width: 100%;
  }

  &.docPadding{
    padding: 0 0 0 69px;

    @media (max-width: break-points.$lg) {
      padding: 0 0 0 56px;
    }
    @media (max-width: break-points.$sm) {
      padding: 0 0 0 39px;
    }
  }

  // @media (max-width: break-points.$lg) {
  //   padding: 0 0 0 56px;
  // }

  // @media (max-width: break-points.$sm) {
  //   padding: 0 0 0 39px;
  // }

  .fileText {
    white-space: pre-line;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;

    &.light {
      color: colors.$textColorLight;
    }

    &.dark {
      color: colors.$white;
    }

    &.docPadding{
      padding-left: 29px;
    }

    @media (max-width: break-points.$sm) {
      font-size: 14px;
      line-height: 24px;
    }

    a {
      opacity: 0.9;
      color: colors.$primaryColor;

      .docIcon {
        margin-bottom: -6px;
        width: 24px;
        height: 24px;

        @media (max-width: break-points.$sm) {
          margin-bottom: -4px;
          width: 18px;
          height: 18px;
        }
      }

      .answerFile {
        font-family: Raleway;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 105px;

        &.light{
          color: colors.$textColorLightMode;
        }

        &.dark{
          color: colors.$lightThemeColor;
        }
      }
    }
  }
}

.messageContent{
  width: 100%;
  word-wrap: break-word; 
  word-break: break-all;
  overflow-wrap: break-word;

}

.imagesContainer{
  display: flex;
  gap: 15px;
  // padding: 0 0 0 25px;
  margin: 0 0 0 25px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

&.imageContent{
  // padding: 0 0 0 98px;
  margin : 0 0 0 98px;

  @media (max-width: break-points.$lg) {
    margin: 0 0 0 69px;
  }
  @media (max-width: break-points.$sm) {
    margin: 0 0 0 63px;
  }
}
}

.image{
  border-radius: 8px;
  width: 200px;
}