@use "../../../scss/colors";

.container {
  width: 100%;
  height: 100%;
}

.featureContainer {
  padding: 60px 15px 0 15px;
  position: relative;
  // position: absolute;
  // left: 0;
  // top: 0;
  // width: 100%;
  // height: 100%;
  // z-index: 1;

  @media screen and (min-width: 769px) {
    padding-top: 100px;
    padding: 0 5%;
  }

  @media screen and (min-width: 1440px) {
    padding: 100px 10% 150px 10%;
  }

  &.light {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.dark {
    background: rgba(42, 40, 49, 0.5);
  }
}


.featureTypeContainer{
  
}

// .featureTypeContainer {
//   padding: 60px 15px 0 15px;
//   position: relative;

//   @media screen and (min-width: 769px) {
//     padding-top: 100px;
//     padding: 0 5%;
//   }

//   @media screen and (min-width: 1440px) {
//     padding: 50px 10% 200px 10%;
//   }

//   &.light {
//     background-color: rgba(255, 255, 255, 0.1);
//   }

//   &.dark {
//     background: #2A2831;
//   }
// }


.title {
  margin: 30px auto 10px auto;
  text-align: center;
  font-size: 24px;
  font-weight: 700;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }

  @media screen and (min-width: 769px) {
    font-size: 40px;
  }
}

.desc {
  text-align: center;
  line-height: 26px;
  font-size: 16px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
    opacity: 0.7;
  }
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 769px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 70px;
  }
}

.col {
  padding: 0 10px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 769px) {
    width: 32%;
    margin-top: 0px;

    &:last-child {
      padding-right: 0;
    }
  }

  @media screen and (min-width: 1440px) {
    width: 25%;
    margin-top: 0px;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;

    &:last-child {
      padding-right: 0;
    }
  }
}

.icon {
  width: 45px;
  height: 45px;

  @media screen and (min-width: 769px) {
    width: 70px;
    height: 70px;
  }
}

.h4 {
  margin: 40px 0 12px 0;
  font-size: 20px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }

  @media screen and (min-width: 769px) {
    font-size: 24px;
  }
}

.text {
  line-height: 24px;
  font-size: 14px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
    opacity: 0.7;
  }

  @media screen and (min-width: 769px) {
    line-height: 26px;
    font-size: 16px;
  }
}
