@use "../../../../../../scss/break-points";
@use "../../../../../../scss/variables";
@use "../../../../../../scss/colors";

$animation-duration: 1.5s;
$delay-between-dots: 1.5s;

@keyframes dotAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.carouselContainer {
  width: 100%;
  padding: 8px 0 0 32px;
  position: relative;

  @media (max-width: break-points.$lg) {
    padding: 8px 0 0 20px;
  }

  @media (max-width: break-points.$sm) {
    padding: 6px 0 0 10px;
  }

  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    white-space: nowrap;
    display: inline-block;
    overflow: hidden;

    &.light {
      color: colors.$textColorLight;
    }

    &.dark {
      color: colors.$white;
    }

    @media (max-width: break-points.$sm) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  .navigationalButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &.prev {
      left: 42px;
      z-index: 1;

      @media (max-width: break-points.$lg) {
        left: 30px;
      }

      @media (max-width: break-points.$sm) {
        left: 20px;
      }
    }

    &.next {
      right: 10px;
    }
  }
}

.carousel {
  width: calc(100vw - (309px + variables.$pagePadding));
  overflow-x: auto;
  display: flex;
  gap: 26px;
  position: relative;
  transition: width variables.$sb-transition ease;

  @media (max-width: break-points.$lg) {
    width: calc(100vw - (210px + variables.$pagePadding));
  }

  @media (max-width: break-points.$md) {
    width: calc(100vw - (200px + variables.$pagePadding));
  }

  @media (max-width: break-points.$sm) {
    width: calc(100vw - (157px + variables.$pagePadding));
  }

  &.isSidebarOpen {
    width: calc(100vw - (variables.$sidebar + 237px));
    transition: width variables.$sb-transition ease;

    @media (max-width: break-points.$lg) {
      width: calc(100vw - (variables.$sidebar + 185px));
    }

    @media (max-width: break-points.$md) {
      width: calc(100vw - 185px);
    }

    @media (max-width: break-points.$sm) {
      width: calc(100vw - 157px);
    }
  }
}

.carousel::-webkit-scrollbar {
  display: none;
}

.carousel {
  scrollbar-width: none;
}

.carousel {
  -ms-overflow-style: none;
}

.carousel {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.imageContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-shrink: inherit;
  border-radius: 20px;

  &.light {
    background-color: rgba(colors.$black, 0.3);
  }

  &.dark {
    background-color: rgba(colors.$darkModeText, 0.3);
  }

  img {
    width: 100%;
    display: block;
    border-radius: 20px;
  }

  .messageContainer {
    border-radius: 20px;
    border: 1px solid colors.$borderDark;
    background-color: rgba(colors.$darkModeText, 0.3);
    backdrop-filter: blur(25px);
    padding: 8px;
    box-sizing: border-box;
    position: absolute;
    color: colors.$textColorLight;
    text-align: center;
    font-size: 11px;
    font-weight: 500;
    line-height: 15px;
  }
}

.button {
  min-width: 36px;
  max-width: 36px;
  min-height: 36px;
  max-height: 36px;
  border-radius: 20px;
  border: 1px solid rgba(colors.$darkModeText, 0.3);
  background-color: rgba(colors.$darkModeText, 0.3);
  backdrop-filter: blur(25px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.actionButtonsContainer {
  position: absolute;
  top: 14px;
  right: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.dot {
  display: inline-block;
  opacity: 0;
  animation: dotAnimation $animation-duration ease-in-out infinite;
  font-size: 20px;
  margin-left: 2px;
}
