@use "../../../../scss/variables";
@use "../../../../scss/break-points";
@use "../../../../scss/colors";

.containerCenter {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: break-points.$sm) {
    padding: 15px;
  }
}

.documentModalContainer {
  padding: 40px 30px;
  width: 100%;
  height: 100%;

  @media (max-width: break-points.$sm) {
    padding: 15px;
  }

  &.dragDocument{
    padding: 0px;
  }
}

.infoContainer {
  padding: 28px 0;
}

.popupContainer {
  width: 471px;
  max-width: calc(100vw - variables.$spacing-lg * 2);
  height: auto;
  border-radius: 20px;
  backdrop-filter: blur(25px);

  &.light {
    border: 1px solid colors.$borderLight;
    background-color: rgba(189, 189, 212, 0.1);
  }

  &.dark {
    border: 1px solid colors.$borderDark;
    background-color: rgba(255, 255, 255, 0.03);
  }

  @media (max-width: 800px) {
    width: 480px;
  }
}

.headline {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  margin-bottom: 9px;

  &.light {
    color: colors.$textColorLight;

    svg {
      & path {
        fill: colors.$textColorLight;
      }
    }
  }

  &.dark {
    color: colors.$white;

    svg {
      & path {
        fill: colors.$white;
      }
    }
  }

  @media (max-width: break-points.$sm) {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 6px;
  }
}

.feature {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;

  @media (max-width: break-points.$sm) {
    font-size: 14px;
    line-height: 22px;
  }
}

.imgfeature {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding: 12px 20px 15px 20px;
  border-radius: 5px 20px 20px 20px;
  

  &.light{
    background-color: colors.$searchfieldActive;
  }
  &.dark{
    background-color: colors.$darkModeSetting;
  }

  @media (max-width: break-points.$sm) {
    font-size: 14px;
    line-height: 22px;
  }
}