@use "../../../../../../scss/fonts";
@use "../../../../../../scss/colors";
@use "../../../../../../scss/break-points";

.pendingHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;

  &.light {
    color: colors.$textColorLight;
  }

  &.dark {
    color: colors.$white;
  }
}

.teamTable {
  width: 100%;
  border-radius: 20px;

  @media (max-width: 862px) {
    border: none;
    border-radius: 0;
  }
  
  thead,
  tbody {
    display: flex;
    flex-direction: column;
    width: 100%;
  

    .separator {
      width: 100%;
      display: none;

      @media (max-width: 862px) {
        display: block;
      }
    }
  }

  thead {
    @media (max-width: 862px) {
      border-bottom: none;
    }
  }

  tbody {
    border-radius: 24px;
    
  }

  tr {
    display: flex;

    @media (max-width: 862px) {
      border-bottom: none;
    }
  }
  tr:last-child {
    border-bottom: none;
  }

  th,
  td {
    font-style: normal;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    text-align: left;
  }

  th {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    padding: 14px 0 14px 16px;
    margin-top: -2px;

    &:first-child {
      border-radius: 19px 0 0 0;
      margin-left: -2px;
    }

    &:last-child {
      border-radius: 0 19px 0 0;
      margin-right: -2px;
    }

    @media (max-width: 862px) {
      background-color: transparent;
      padding: 5px 10px 5px 10px;
      font-size: 12px;
      margin-right: 1px;

      &:first-child {
        border-radius: 0;
      }

      &:last-child {
        border-radius: 0;
      }
    }
  }

  td {
    padding: 16px 0 16px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    @media (max-width: 862px) {
      padding: 5px 10px 5px 10px;
    }
  }

  tr {
    th {
      &:first-child {
        padding-left: 16px;

        @media (max-width: 862px) {
          visibility: hidden;
          padding-left: 0px;
        }
      }
    }

    td {
      display: flex;
      align-items: center;

      &:first-child {
        padding-left: 16px;

        .name {
          display: block;

          @media (max-width: 862px) {
            display: none;
          }
        }

        .firstChild {
          width: 100%;
          display: none;
          flex-direction: column;
          align-items: flex-start;
          white-space: nowrap;
          padding: 2px 17px;
          @media (max-width:break-points.$sm) {
            padding: 7px 12px ;
          }

          div {
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
          }

          @media (max-width: 862px) {
            display: flex;
          }
        }

        @media (max-width: 862px) {
          padding-left: 0px;
        }
      }
    }

    td {
      font-family: fonts.$regularFont;
    }

    td,
    th {
      &:nth-child(1) {
        width: 17%;

        @media (max-width: 862px) {
          width: 39%;
        }
      }

      &:nth-child(2) {
        width: 19%;

        @media (max-width: 862px) {
          display: none;
        }
      }

      &:nth-child(3) {
        width: 14%;

        @media (max-width: 862px) {
          display: none;
        }
      }

      &:nth-child(4) {
        width: 14%;

        @media (max-width: 862px) {
          display: none;
        }
      }

      &:nth-child(5) {
        width: 11%;

        @media (max-width: 862px) {
          width: 17%;
        }
      }

      &:nth-child(6) {
        width: 11%;

        @media (max-width: 862px) {
          width: 17%;
        }
      }

      &:nth-child(7) {
        width: 11%;

        @media (max-width: 862px) {
          width: 17%;
        }
      }

      &:nth-child(8) {
        width: 7%;
        cursor: pointer;
        position: relative;

        @media (max-width: 862px) {
          width: 10%;
        }
      }
    }
  }
}

.teamTableLight {
  color: colors.$textColorLight;

  thead,
  tbody {
    .separator {
      border-bottom: 1px solid rgba(colors.$textColorLightMode, 0.03);
    }     
  }

  tbody {
    background: colors.$darkModeText;
    @media (max-width:break-points.$md) {
      background: colors.$mainViewColor;
    }
  }

  tr {
    border-bottom: 1px solid rgba(colors.$textColorLightMode, 0.03);
  }
}

.teamTableDark {
  color: colors.$white;

  thead,
  tbody {
    .separator {
      border-bottom: 1px solid rgba(colors.$darkModeText, 0.03);
    }
  }

  tbody {
    background: colors.$tbodybackground;
    @media (max-width:break-points.$md) {
      background: colors.$tbodyDarkMode;
    }
  }

  tr {
    border-bottom: 1px solid rgba(colors.$darkModeText, 0.03);
  }
}
