@use "../../../../../../../scss/colors";
@use "../../../../../../../scss/break-points";

.container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top : 25px;

  @media (max-width: break-points.$md) {
    margin-top : 0px;
  }

  &.light {
    color: rgba(colors.$textColorLightMode, 0.9);

    @media (min-width: 775px) {
      svg {
        & path {
          stroke: colors.$creditColor;
        }
      }
    }
  }

  &.dark {
    color: rgba(colors.$darkModeText, 0.9);

    @media (min-width: 775px) {
      svg {
        & path {
          stroke: colors.$darkModeText;
        }
      }
    }
  }

  &.team {
    color: colors.$darkModeText;
    margin-top : 0px;

    svg {
      & path {
        stroke: colors.$darkModeText;
      }
    }
  }

  &.chatCredit {
    color: rgba(colors.$darkModeText, 0.9);

    @media (min-width: 775px) {
      svg {
        & path {
          stroke: colors.$creditColor;
        }
      }
    }

    @media (max-width: break-points.$md) {
      svg {
        & path {
          stroke: colors.$darkModeText;
        }
      }
    }
  }
&.chat{
  margin-top: 0px;
  @media (max-width: break-points.$md) {
      color: colors.$darkModeText;

      svg {
        & path {
          stroke: colors.$darkModeText;
        }
      }
    }
  }

  &.miniSidebar{
    display: flex;
    justify-content: center;
  }

  .credits {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    opacity: 0.9;

  }

  &.isSetting{
    color: colors.$darkModeText;

    svg {
      & path {
        stroke: colors.$darkModeText;
      }
    }
  }
}