@use '../../.../../../../../../../src/scss/colors';
@use '../../.../../../../../../../src/scss/break-points';

.textarea {
    width: 100%;
    resize: none;
    background-color: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border-radius: 10px;
    height: 24px;
    padding: 16px;
    white-space: pre-line;

    &.light {
        color: colors.$textColorLight;
        border: 1px solid colors.$promptborder;

        &::placeholder {
            color: colors.$textColorLightMode;
            opacity: 0.6;
        }
    }

    &.dark {
        color: colors.$white;
        border: 1px solid rgba(colors.$darkModeText, 0.1);

        &::placeholder {
            color: colors.$darkModeText;
            opacity: 0.6;
        }
    }

    &.error {
        border-color: transparent;
        border-color: colors.$red;
    }

    @media (max-width: break-points.$sm) {
        font-size: 14px;
    }

    &.activeborder {
        border-color: colors.$primaryColor;
    }
}

.CustomButtonPrompt {
    padding: 10px 40px !important;
}

.activeborder {
    border-bottom: 2px solid;
}

.darkBorder{
    border-bottom: 2px solid colors.$darkModeText !important;
}

.lightBorder{
    border-color: colors.$darkModeText;
}