@use "../../../../scss/break-points";
@use '../../../../scss/colors';

.tableContainer {
  padding: 18px;
  border-radius: 24px;
  margin-left: 10px;

  @media (max-width:break-points.$sm) {
    padding: 18px 0px; 
  }


  &.isLoading {
    width: 100%;
    height: 100%;
  }

  &.light {
    background-color: rgba(colors.$textColorLightMode, 0.04);
    @media (max-width:break-points.$md) {
      background-color: colors.$darkModeText;
    }
    @media (max-width:break-points.$sm) {
      background-color: colors.$darkModeText;
    }
  }

  &.dark {
    background-color: colors.$darkModeSetting;
    @media (max-width:break-points.$md) {
      background-color: colors.$tbodybackground;
    }
    @media (max-width:break-points.$sm) {
      background-color: colors.$darkModeBackground;
    }
  }
}
