@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.message {
  white-space: pre-line;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;

  &.light {
    color: rgba(colors.$textColorLight, 0.9);
  }

  &.dark {
    color: rgba(colors.$white, 0.9);
  }

  &.styleTextArea{
      min-height: unset !important;
      max-height: unset !important;
    
  }

  .userMessage {
    padding: 12px 20px 15px 20px;
    border-radius: 5px 20px 20px 20px;

    &.light {
      background-color: colors.$searchfieldActive;
    }

    &.dark {
      background-color:  colors.$darkModeSetting;
    }

    &.sharchat{
      padding: 12px 0px 15px 0px;
      background-color: transparent;
    }
  }

  ul {
    padding-left: 1em;
    li {
      position: relative;
      padding-left: 10px;
      margin-bottom: 10px;

      &:before {
        // content: "\2022";
        position: absolute;
        // left: -4px;
        top: 0;
        // color: rgba(colors.$darkModeText, 0.9);
        font-size: 28px;
      }
    }
  }

  ol {
    padding-left: 1.6em;
    li {
      margin-bottom: 10px;
    }
  }

  p {
    margin-bottom: 10px;
  }

  @media (max-width: break-points.$sm) {
    font-size: 14px; 
    line-height: 24px;
  }
}
