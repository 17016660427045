@use "../../../../../../scss/break-points";
@use "../../../../../../scss/colors";

.shareChatFooter{
    height: 100px;
    padding-right: 10px;
    padding-left: 10px;
    @media (max-width: break-points.$sm) {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  .selectedMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    opacity: 0.8;

    &.light{
      color: colors.$textColorLightMode;
      background-color: colors.$darkModeText;
    }

    &.dark{
      color: colors.$darkModeText;
    }

    &.sharefooterbottom{
      width:70%;
      margin-bottom: -3px;

      @media (max-width:break-points.$sm) {
        width:100%
      }
    }

    &.sharefooterclr{
      background-color: colors.$shareChatLight;
    }

  }

  .shareLinkbtn{
    margin-left: 50px;
    width: 50%;
    @media (max-width:break-points.$sm) {
      margin-left: 0px;
      width: 100%;
    }
    
  }
  .Continuebtn{
    width: 50%;
    padding: 10px;
    @media (max-width:break-points.$sm) {
      margin-left: 0px;
      width: 100%;
    }
  }

  .cancelbtn{
    border: 1px solid #9F75FF;
     margin-left: 10px;
     @media (max-width: break-points.$sm) {
      border: none;
      margin-left: 5px;
     }
  }

  .btnText {
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    color: colors.$cancelShareBtn;
    padding: 0px 13px;

    @media (max-width: break-points.$sm) {
      padding: 0;
    }

  }
  .btnNewText{
    font-family: Raleway;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    opacity: 0.8;
    color:  colors.$primaryColor;
  }
  .newChat{
    border: 1.5px solid colors.$primaryColor;
    border-radius: 10px;
    background-color: transparent;
    margin-left: 20px;
    padding: 4px 16px 4px 16px !important;
    
  }
  .buttonMainContainer{
    margin-right: -4px;
    @media (max-width:break-points.$sm) {
      margin-right: -23px;
    }
  }

  .checkboxContainer {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding: 0px 20px;
    
  }
  
  .checkboxContainer input {
    display: none;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 2px solid colors.$checkBorder;
    border-radius: 50%;
  }
  .checkboxContainer input:checked ~ .checkmark {
    background-color: colors.$primaryColor; 
    border-color:  colors.$primaryColor;
  }
  
  .checkboxContainer input:checked ~ .checkmark:after {
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 18 13" fill="none"> <path d="M6.96994 12.5304L0.969937 6.53039C0.676688 6.23714 0.676688 5.76239 0.969937 5.46989L2.03044 4.40939C2.32369 4.11614 2.79844 4.11614 3.09094 4.40939L7.50019 8.81864L15.6594 0.659391C15.9527 0.366141 16.4274 0.366141 16.7199 0.659391L17.7804 1.71989C18.0737 2.01314 18.0737 2.48789 17.7804 2.78039L8.03044 12.5304C7.73794 12.8236 7.26244 12.8236 6.96994 12.5304Z" fill="white"/></svg>');
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .newChatBtn {
    width: 240px;
    padding: 0.4em 0;
    border: 1.5px solid colors.$primaryColor;
    border-radius: 10px;
    background-color: transparent;
  }
  .btnContent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;

    .text {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color:  colors.$primaryColor;
    }
    .continuetext {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: colors.$darkModeText;
    }
    
  }
  .RadioContainer{
    margin-right: 15px;
    margin-bottom: -25px;
  }

.customRadio[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 25px !important;
  height: 25px !important;
  border: 1.5px solid colors.$primaryColor;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  background-color: transparent;
}

.customRadio[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 15px !important;
  height: 15px !important;
  background-color: colors.$primaryColor;
  border-radius: 50%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

